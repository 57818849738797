<template>
  <div class="newsPage pageHover system">
    <!-- <div class="title">
      <span class="primaryTitle">新闻动态</span>
      <span class="more">MORE</span>
    </div> -->
    <el-row class="title">
      <el-col :span="13" :offset="5" class="primaryTitle sketchTitle">
        新闻动态
        <!-- <span class="more" @click="clickNewsMore">MORE</span> -->
      </el-col>
      <el-col :span="5" class="more" @click="clickNewsMore">MORE</el-col>
    </el-row>

    <el-divider></el-divider>

    <el-row
      class="information"
      @mouseover="enterEle(index)"
      @mouseleave="leaveEle(index)"
      v-for="(item, index) in newsLists"
      :key="item.id"
    >
      <!-- 描述 -->
      <el-col :span="13" class="describeCol">
        <el-row class="secondaryTitleRow">
          <el-col :span="19" @click="clickNews(item.id)" class="secondaryTitle"
            >{{ item.newsTitle }}
          </el-col>
          <el-col :span="4" :offset="1" class="day">{{ item.newsTime }}</el-col>
        </el-row>
        <el-row class="abstract">{{ item.newsShortBody }}</el-row>
        <el-row class="btnRow">
          <el-button @click="clickNews(item.id)" class="btn"
            >查看更多</el-button
          >
        </el-row>
      </el-col>
      <!-- 图片 -->
      <el-col :span="9" :offset="2" @click="clickNews(item.id)" class="imgCol">
        <img v-if="item.images" :src="item.images" class="newsImg" />
        <img v-else src="../assets/photo/news/rocket.png" class="newsImg" />
      </el-col>
      <el-divider></el-divider>
    </el-row>
  </div>
</template>
<script>
import { getlastest as getnews } from "@/api/news.js";

export default {
  data() {
    return {
      newsLists: [],
    };
  },
  mounted() {
    this.getNews();
  },
  methods: {
    enterEle(index) {
      // console.log(index);
      // console.log("in");
      const ele = document.getElementsByClassName("information");
      const eleColor = document.getElementsByClassName("secondaryTitle");
      for (const i of ele) {
        // console.log(i);
        i.classList.remove("animated-moveRight");
      }
      eleColor[index].classList.add("headerHoverColor");
      ele[index].classList.add("animated-moveRight");
    },
    leaveEle(index) {
      // console.log("out");
      const eleColor = document.getElementsByClassName("secondaryTitle");
      const ele = document.getElementsByClassName("information");
      ele[index].classList.remove("animated-moveRight");
      eleColor[index].classList.remove("headerHoverColor");
    },
    clickNews(id) {
      this.$router.push({
        path: "/Newsdetail",
        query: {
          id: id,
        },
      });
    },
    getNews() {
      getnews().then((res) => {
        console.log(res);
        this.newsLists = res.data
      });
    },
    clickNewsMore() {
      this.$router.push("/Newsinformation");
    },
  },
};
</script>
<style scoped>
.animated-moveRight {
  animation-duration: 500ms;
  animation-name: slideright;
  animation-fill-mode: forwards;
}

.headerHoverColor {
  color: #0092de !important;
}

@keyframes slideright {
  from {
    margin-left: 420px;
    /*width: 100%;*/
  }

  to {
    margin-left: 430px;
    /*width: 100%;*/
  }
}

.title {
  text-align: center;
  padding-bottom: 38px;
}

.more {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 4px;
  padding-top: 80px;
  text-align: left;
  cursor: pointer;
}
.more:hover{
  color: #66BFEE;
}

.el-divider--horizontal {
  width: 1076px;
  height: 1px;
  background: #cccccc;
  margin: 0px 423px;
}

.information {
  width: 1076px;
  height: 275px;
  margin: 0 auto;
}

.describeCol {
  height: 100%;
}

.secondaryTitleRow {
  cursor: pointer;
  padding-bottom: 29px;
  padding-top: 20px;
}

.secondaryTitle {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
}

.day {
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #999999;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.abstract {
  line-height: 25px;
  letter-spacing: 3px;
  text-align: justify;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.btnRow {
  padding-top: 27px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}

.el-button {
  width: 100px;
  height: 50px;
  background: #0092de;
  border-radius: 25px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  letter-spacing: 2px;
}

.imgCol {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.newsImg {
  width: 360px;
  height: 210px;
  margin-left: 45px;
}

.information .el-divider--horizontal {
  margin: 0;
}
</style>
