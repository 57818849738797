<template>
  <el-row class="aboutUs pageHover">
    <el-col :span="7" :offset="5">
      <el-image
        class="companyLocationImg"
        :src="aboutUrl"
        :preview-src-list="aboutList"
      />
    </el-col>
    <el-col :span="7" class="introductionCol">
      <div class="introduction">
        <div class="aboutTitle">关于我们</div>
        <div class="content">
          广州信瑞医疗技术有限公司是专注于开发智慧医疗和医疗大数据应用领域，集研发、生产、销售及服务为一体的高科技企业，广州市创业领军团队项目，核心团队由美籍华裔科学家、亚利桑那大学兼职教授、复旦大学兼职教授；中科院国家特津专家和清华教授组成。公司打造以区域龙头医院为中心、连接社区、赋能院内资源、辐射医联体，实现安全决策医疗。
        </div>
        <div class="btnRow">
          <el-button @click="showAboutUs" class="btn">查看更多</el-button>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { ref } from "@vue/reactivity";

export default {
  setup() {
    //正常的图片
    const aboutUrl = ref(require("@/assets/photo/about/companyLocation.png"));
    //点击后的图片
    const aboutList = ref([
      require("@/assets/photo/el-img/el-companyLocation.jpg"),
    ]);
    return {
      aboutUrl,
      aboutList,
    };
  },

  methods: {
    showAboutUs() {
      this.$router.push("/Aboutus/");
    },
  },
};
</script>
<style scoped>
.el-image-viewer__mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  background: #000;
}
.btn:hover {
  color: #0092de;
  background: #fbfbfb;
  border: 1px solid #0092de;
}
.aboutUs {
  height: 555px;
  /* margin-top: 59px; */
}
.companyLocationImg {
  margin-left: 15px;
}
.introduction {
  width: 500px;
  height: 290px;
}
.introductionCol {
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutTitle {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 4px;
  text-align: center;
  height: 70px;
}
.content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 3px;
  line-height: 23px;
  text-align: justify;
}
.btnRow {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-button {
  width: 100px;
  height: 50px;
  background: #0092de;
  border-radius: 25px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  letter-spacing: 2px;
}
</style>
