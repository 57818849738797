<template>
  <div class="rosepathologyPage">
    <el-row class="brainRow" v-if="type != 'Pathology'">
      <el-col :span="10" :offset="1" v-for="brain in brainData" :key="brain">
        <el-row class="traitRow">
          <el-col :span="2" class="traitCol">
            <span class="bigPoint">{{ brain.num }}</span>
          </el-col>
          <el-col :span="13" class="brainText">{{ brain.brainText }}</el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="iceRow" v-else>
      <el-col :span="10" :offset="1" v-for="ice in iceData" :key="ice">
        <el-row class="traitRow">
          <el-col :span="2" class="traitCol">
            <span class="bigPoint">{{ ice.num }}</span>
          </el-col>
          <el-col :span="13" class="brainText">{{ ice.iceText }}</el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="imgRow">
      <el-col :span="8" :offset="0">
        <el-row class="center">
          <!-- <img src="@/assets/photo/brain/photo1.png" /> -->
          <el-image :src="photo1Url" :preview-src-list="photo1List" />
        </el-row>
        <el-row class="center">
          <div class="rosepathologyText">移动端</div>
        </el-row>
        <el-row class="center rowTwo">
          <!-- <img src="@/assets/photo/brain/photo2.png" /> -->
          <el-image :src="photo2Url" :preview-src-list="photo2List" />
        </el-row>
        <el-row class="center">
          <div class="rosepathologyText">病理专家</div>
        </el-row>
      </el-col>
      <el-col :span="5" :offset="0">
        <el-row class="center fiveGeneration">
          <img src="@/assets/photo/brain/photo3.png" />
        </el-row>
        <el-row class="center rowTwo">
          <img src="@/assets/photo/brain/photo4.png" />
        </el-row>
      </el-col>

      <el-col :span="8" :offset="1" v-if="type != 'Pathology'">
        <el-row class="center section">
          <el-image :src="photo5Url" :preview-src-list="photo5List" />
        </el-row>
        <el-row class="center">
          <div class="rosepathologyText">5G远程切片放大系统</div>
        </el-row>
        <el-row class="center rowTwo">
          <el-image :src="photo6Url" :preview-src-list="photo6List" />
        </el-row>
        <el-row class="center">
          <div class="rosepathologyText">快速染色台</div>
        </el-row>
      </el-col>

      <el-col :span="9" :offset="1" v-else>
        <el-row class="center sectionTwo">
          <!-- <img src="@/assets/photo/brain/section.png" alt="" /> -->
          <el-image :src="sectionUrl" :preview-src-list="sectionList" />
        </el-row>
        <el-row class="center">
          <div class="rosepathologyText">5G远程切片放大系统</div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    //正常的图片
    const photo1Url = ref(require("@/assets/photo/brain/photo1.png"));
    const photo2Url = ref(require("@/assets/photo/brain/photo2.png"));
    const photo5Url = ref(require("@/assets/photo/brain/photo5.png"));
    const photo6Url = ref(require("@/assets/photo/brain/photo6.png"));
    const sectionUrl = ref(require("@/assets/photo/brain/section.png"));
    //点击后的图片
    const photo1List = ref([
      require("@/assets/photo/el-img/rosepathology/el-photo1.png"),
    ]);
    const photo2List = ref([
      require("@/assets/photo/el-img/rosepathology/el-photo2.png"),
    ]);
    const photo5List = ref([
      require("@/assets/photo/el-img/rosepathology/el-photo5.png"),
    ]);
    const photo6List = ref([
      require("@/assets/photo/el-img/rosepathology/el-photo6.png"),
    ]);
    const sectionList = ref([
      require("@/assets/photo/el-img/rosepathology/el-section.png"),
    ]);
    return {
      photo1Url,
      photo2Url,
      photo5Url,
      photo6Url,
      sectionUrl,
      photo1List,
      photo2List,
      photo5List,
      photo6List,
      sectionList,
    };
  },
  data() {
    return {
      brainData: [
        {
          num: "1",
          brainText: "让冰冻诊断医生随时随地做诊断，不需要在手术室等待",
        },
        {
          num: "2",
          brainText: "为托管医院、帮扶医院提供远程细胞诊断",
        },
        {
          num: "3",
          brainText: "为“飞刀”医生提供手术需要的细胞病理诊断",
        },
        {
          num: "4",
          brainText: "可以一位或多位医生做远程诊断",
        },
        {
          num: "5",
          brainText: "在手术室门外做涂片、染色及病理诊断",
        },
      ],
      iceData: [
        {
          num: "1",
          iceText: "让冰冻诊断医生随时随地做诊断，不需要在冰冻室等着",
        },
        {
          num: "2",
          iceText: "为托管医院、帮扶医院提供远程冰冻诊断",
        },
        {
          num: "3",
          iceText: "为“飞刀”医生提供手术需要的术中冰冻病理诊断",
        },
        {
          num: "4",
          iceText: "可以一位或多位医生做远程诊断",
        },
      ],
      // isShowNoFive: false,
      type: "",
    };
  },
  created() {
    const all = this.$route.path.split("/");
    this.type = all[1];
    if (all[1] === "Pathology") {
      // this.isShowNoFive = false;
    } else {
      // this.isShowNoFive = true;
    }
  },
};
</script>
<style scoped>
.traitRow {
  height: 42px;
  display: flex;
  align-items: center;
}
.traitCol {
  display: flex;
  align-items: center;
}
.rosepathologyText {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #666666;

  letter-spacing: 2px;
  margin-top: 20px;
}
.rosepathologyPage {
  height: 83%;
}

.bigPoint {
  width: 30px;
  height: 30px;
  background: #0092de;
  border-radius: 50%;
  position: absolute;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brainRow,
.iceRow {
  width: 80%;
  height: 28%;
  margin-left: 20%;
}

.brainText {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 3px;
  text-align: justify;
}

.imgRow {
  width: 63%;
  height: 64%;
  margin-left: 22%;
}

.center {
  justify-content: center;
}

.rowTwo {
  margin-top: 46px;
}

.fiveGeneration {
  margin-top: 95px;
}

.section {
  margin-top: 26px;
}

.sectionTwo {
  margin-top: 60px;
}
</style>
