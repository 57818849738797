<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">智能护理交互室系统</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">功能模块</el-col>
      </el-row>
      <div class="sketchText">
        <div class="words functionWords">
          护理任务自主生成，护理任务分类展示，任务状态清晰记录，输液监控危急值预警，安排情况实时提醒，患者信息实时调阅，护理任务自主设定，借物维修记录明确，病区统计一目了然，护士助手智能响应。
        </div>
      </div>

      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">任务状态动态展示</el-col>
      </el-row>

      <div class="imgRow">
        <!-- <img src="@/assets/photo/Nursing/img1.png" /> -->
        <el-image :src="img1Url" :preview-src-list="img1List" />
      </div>
    </div>

    <div class="twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">输液监控/呼叫预警</el-col>
      </el-row>
      <div class="imgRow">
        <!-- <img src="@/assets/photo/Nursing/img2.png" /> -->
        <el-image :src="img2Url" :preview-src-list="img2List" />
      </div>
    </div>

    <div class="threeRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">患者临床数据展示</el-col>
      </el-row>
      <div class="imgRow">
        <!-- <img src="@/assets/photo/Nursing/img3.png" /> -->
        <el-image :src="img3Url" :preview-src-list="img3List" />
      </div>
    </div>

    <div class="fourRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">手术安排</el-col>
      </el-row>
      <div class="imgRow">
        <!-- <img src="@/assets/photo/Nursing/img4.png" /> -->
        <el-image :src="img4Url" :preview-src-list="img4List" />
      </div>
    </div>

    <div class="fifthRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">科室患者展示</el-col>
      </el-row>
      <div class="imgRow">
        <!-- <img src="@/assets/photo/Nursing/img5.png" /> -->
        <el-image :src="img5Url" :preview-src-list="img5List" />
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { ref } from "@vue/reactivity";
export default {
  components: {
    Footer,
  },
  setup() {
    //正常的图片
    const img1Url = ref(require("@/assets/photo/Nursing/img1.png"));
    const img2Url = ref(require("@/assets/photo/Nursing/img2.png"));
    const img3Url = ref(require("@/assets/photo/Nursing/img3.png"));
    const img4Url = ref(require("@/assets/photo/Nursing/img4.png"));
    const img5Url = ref(require("@/assets/photo/Nursing/img5.png"));
    //点击后的图片
    const img1List = ref([
      require("@/assets/photo/el-img/Nursing/el-img1.png"),
    ]);
    const img2List = ref([
      require("@/assets/photo/el-img/Nursing/el-img2.png"),
    ]);
    const img3List = ref([
      require("@/assets/photo/el-img/Nursing/el-img3.png"),
    ]);
    const img4List = ref([
      require("@/assets/photo/el-img/Nursing/el-img4.png"),
    ]);
    const img5List = ref([
      require("@/assets/photo/el-img/Nursing/el-img5.png"),
    ]);
    return {
      img1Url,
      img2Url,
      img3Url,
      img4Url,
      img5Url,
      img1List,
      img2List,
      img3List,
      img4List,
      img5List,
    };
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped>
.oneRow {
  height: 853px;
}
.twoRow {
  height: 575px;
  background: #f5f5f7;
}
.threeRow {
  height: 577px;
}
.fourRow {
  height: 490px;
  background: #f5f5f7;
}
.fifthRow {
  height: 603px;
}

.imgRow {
  display: flex;
  justify-content: center;
  margin-bottom: 59px;
}
.sketchText {
  margin: 0;
}
.functionWords {
  width: 629px;
}
</style>
