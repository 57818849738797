<template>
  <div class="productBg" @mouseover="over" @mouseleave="leave">
    <!--    {{ systemInfo.url }}-->
    <div class="product-container">
      <el-row class="product-row">
        <el-col :span="14" class="product-photo">
          <!-- <el-row>
            <el-col :span="24"> -->
          <img :src="systemInfo.url" alt="" />
          <!-- </el-col>
          </el-row> -->
        </el-col>
        <el-col :span="10" class="product-info">
          <div class="product-name" :style="active">
            <span class="system-name">{{ systemInfo.name }}</span>
          </div>
          <div class="product-description">
            <span>{{ systemInfo.description }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  props: {
    systemInfo: Object,
  },
  data() {
    return {
      active: "",
    };
  },
  methods: {
    over() {
      this.active = "color:#66bfee";
    },
    leave() {
      this.active = "";
    },
  },
};
</script>

<style scoped lang="scss">
.productBg {
  width: 520px;
  height: 300px;
  background: #f5f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.productBg:hover {
  width: 520px;
  height: 300px;
  background: #f5f5f7;
  box-shadow: 0px 4px 10px 0px rgba(11, 85, 123, 0.4);
}

.product-container {
  //display: flex;
  width: 100%;
}
.product-row {
  height: 245px;
}

.product-photo {
  //width: 270px;
  //flex: 1;
  text-align: center;
  border-right: 1px solid #cccccc;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    // width: 270px;
  }
}

.product-info {
  //width: 100px;
  padding-left: 12px;

  .product-name {
    width: 180px;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    color: #666666;
    vertical-align: middle;
    letter-spacing: 7px;
    padding-top: 23px;
    padding-bottom: 54px;
    .system-name {
      display: inline-block;
    }
  }

  .product-description {
    width: 180px;
    // margin-top: -10px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    letter-spacing: 3px;
    line-height: 24px;
    text-align: justify;
  }
}
</style>
