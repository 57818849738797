<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">5G远程ROSE病理评估</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统简介</el-col>
      </el-row>
      <el-row class="introduction">
        <el-col :span="8" :offset="2">
          <el-row class="introductionText">
            <el-col :span="1">1）</el-col>
            <el-col :span="22" :offset="1">通过5G网络，病理医生在移动端或办公室做远程病理评估</el-col>
            
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">2）</el-col>
            <el-col :span="22" :offset="1">
            配置物镜4X/10X/20X/40X（可选60X）</el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">3）</el-col>
            <el-col :span="22" :offset="1">
            活检无需送病理科，快速染色台现场做切片（不超过1分钟）</el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">4）</el-col>
            <el-col :span="22" :offset="1">
            保留本地诊断操作，并可输出到显示器</el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">5）</el-col>
            <el-col :span="22" :offset="1">5G/4G宽带任意切换</el-col>
             </el-row>
          <el-row class="introductionText">
            <el-col :span="1">6）</el-col>
            <el-col :span="22" :offset="1">
            神经脑外科、呼吸科、妇科、内镜室、B超室、肿瘤科等手术室或门诊做病理诊断、早癌筛查</el-col>
            </el-row>
        </el-col>
        <el-col :span="8" :offset="3" class="roseCol">
          <div class="greatCircle">
            <span class="greatCircleText">ROSE病理</span>
          </div>
          <div class="box">
            <div class="boxText boxTop">现场快速病理评估ROSE</div>
            <div class="boxText">(RapidOn-SiteEvaluation)</div>
            <div class="boxText">
              是在手术室现场，病理医生在3分钟内给手术医生病理评估
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统特点</el-col>
      </el-row>
      <rosepathology></rosepathology>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import rosepathology from "@/components/rosepathology.vue";
import "@/assets/css/system.css";
export default {
  components: {
    Footer,
    rosepathology,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.oneRow {
  height: 546px;
}
.twoRow {
  height: 743px;
  background: #f5f5f7;
}
.introduction {
  width: 70%;
  /* height: 63%; */
  margin-left: 18%;
}
.introductionText {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
  margin-top: 10px;
  text-align: justify;
}
.roseCol {
  padding-top: 50px;
}
.greatCircle {
  width: 80px;
  height: 80px;
  background: #0092de;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 11;
}
.greatCircleText {
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #fbfbfb;
  letter-spacing: 4px;
  text-align: center;
  width: 58px;
  height: 36px;
}
.box {
  width: 310px;
  height: 140px;
  border: 1px solid #0092de;
  border-radius: 8px;
  margin: 37px 0 0 39px;
  position: relative;
}
.boxText {
  width: 265px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
  margin-left: 35px;
  text-align: justify;
  line-height: 22px;
}
.boxTop {
  margin-top: 30px;
}
</style>
