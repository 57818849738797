<template>
  <div class="system">
    <operation></operation>

    <!-- <div class="sketch oneRow">
      <div class="sketchTitle">安全决策手术系统</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统特点</el-col>
      </el-row>
      <el-row class="characteristic">
        <el-col :span="3" :offset="6">
          <el-image :src="machineUrl" :preview-src-list="machineList" />
        </el-col>
        <el-col :span="3">
          <div class="textOne textLeft">
            <div class="textTitle">患者全景医疗信息汇总</div>
            <div class="textBody">质量提升了</div>
          </div>
          <div class="textTwo textLeft">
            <div class="textTitle">手术医师直接操作影像</div>
            <div class="textBody">影像三维重构分屏显示</div>
            <div class="textBody">语音记录转手术病历</div>
          </div>
        </el-col>
        <el-col :span="4">
          <img src="@/assets/photo/Operation/relationship.png" />
        </el-col>
        <el-col :span="3">
          <div class="textOne textRight">
            <div class="textTitle">非接触操作保证无菌安全</div>
            <div class="textBody">感染控制提升了</div>
          </div>
          <div class="textTwo textRight">
            <div class="textTitle">多模态智能人机交互</div>
            <div class="textBody">语音识别,手势识别,虚拟屏幕</div>
          </div>
        </el-col>
      </el-row>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">手术核查</el-col>
      </el-row>
      <el-row>
        <el-col :span="7" :offset="6" class="textBody"
          >麻醉诱导前核查，手术开始前、切皮前、出手术室前的多方核对、签字。</el-col
        >
        <el-col :span="3" :offset="1">
          <el-image :src="checkUrl" :preview-src-list="checkList" />
        </el-col>
      </el-row>
    </div> -->

    <div class="fourRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>

        <el-col class="functionalCol" :span="3">术后功能</el-col>
      </el-row>
      <div class="window">
        <div class="windowTitle">术中记录查看 | 历史记录全景展示</div>
        <div class="windowTwo">手术室场景的立体全流程再现</div>
        <div class="windowThree">支持科研项目开展</div>
        <div class="windowTwo">促进术中医疗文书记录多媒体化</div>
        <div class="windowThree">让术中记录信息更为充分写实</div>
        <div class="windowTwo">提供术中医学信息大数据分析基础</div>
        <div class="windowThree">支持病案分析统计</div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import operation from "@/components/operation.vue";

export default {
  components: {
    Footer,
    operation
  },
  
};
</script>
<style scoped>


.fourRow {
  height: 467px;
  background: url("../assets/photo/Operation/background.png");
}

.window {
  width: 420px;
  height: 254px;
  background: #ffffff;
  border-radius: 17px;
  margin-left: 40%;
  text-align: center;
}

.windowTitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0092de;
  letter-spacing: 3px;
  padding-top: 14px;
  padding-bottom: 20px;
}

.windowTwo {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.windowThree {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

/* .operationText {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  margin-top: 10px;
} */
</style>
