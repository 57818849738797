<template>
<div class="bannerPage">
  <div class="swiper-container banner">
    <div class="swiper-wrapper">
      <div
        v-for="item in swiperList"
        :key="item.id"
        class="swiper-slide"
        @click="clickBanner(item.name)"
      >
        <img :src="item.url" alt="" />
      </div>
    </div>
    <div class="swiper-button-prev backBtn"></div>
    <div class="swiper-button-next forwardBtn"></div>
    <!-- Add Pagination -->
    <div class="swiper-pagination banner"></div>
  </div>
</div>
  
</template>
<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper";
import { getswiper } from "@/api/swiper.js";
import "./../../assets/css/swiper.css";

export default {
  components: {},
  data() {
    return {
      swiperList: [],
    };
  },
  mounted() {
    setTimeout(() => {
      new Swiper(".banner .swiper-container", {
        loop: true, // 循环模式选项
        observer: true,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".forwardBtn",
          prevEl: ".backBtn",
        },
      });
    }, 300);
    getswiper().then((res) => {
      console.log(res);
      this.swiperList = res.data;
    });
  },
  methods: {
    clickBanner(name) {
      this.$router.push("/" + name + "/");
    },
  },
};
</script>
<style scoped lang="scss">
.swiper-container {
  width: 100%;
  height: 100%;
  --swiper-pagination-color: #fbfbfb;
}

.swiper-slide {
  cursor: pointer;
  // width: 100% !important;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
