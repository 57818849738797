<template>
  <div class="container">
    <div class="items">
      <div class="line">1</div>
      <div class="line">2</div>
      <div class="line">3</div>
      <div class="line">111</div>
      <div class="line">111</div>
      <div class="line">111</div>
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->
      <!--      <div class="line">111</div>-->

    </div>
    <div class="footer">footer</div>
  </div>
</template>
<script>
export default {
  mounted() {
    // this.check()
  },
  methods: {
    consoleOpenCallback() {
      alert("CONSOLE OPEN");
    },
    check() {
      const handler = setInterval(() => {
        const before = new Date();
        debugger;
        const after = new Date();
        const cost = after.getTime() - before.getTime();
        if (cost > 100) {
          this.consoleOpenCallback();
          clearInterval(handler)
        }
      }, 1000)
    }

  }
}
;
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.items {
  flex: 1;
}

.line:nth-child(even) {
  background: #9b9b9b;
  width: 100%;
  height: 55px;
  text-align: center;
}

.line:nth-child(odd) {
  background: #6666;
  width: 100%;
  height: 50px;
  text-align: center;
}

.footer {
  text-align: center;
  height: 50px;
  background-color: #00ff33;
}
</style>
