<template>
  <!-- <div class="headerTitle">
    <div @click="showRecruit" class="headerWords">招贤纳士</div>
  </div> -->
  <div @click="showRecruit" class="headerTitle">招贤纳士</div>
</template>
<script>
export default {
  methods: {
    showRecruit() {
      this.$router.push("/Recruit");
    },
  },
};
</script>
<style scoped>
.headerTitle {
  width: 84px;
  cursor: pointer;
}
/* .headerWords {
  width: 84px;
  height: 90px;
}
.headerWords:hover {
  border-bottom: 8px solid #0092de;
} */
</style>
