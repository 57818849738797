<template>
  <div v-if="news" class="newsDetailPage">
    <el-row class="newsDetailRow titleRow">
      <el-col
        :span="16" :offset="3" class="detailTitle"
      >{{ news.newsTitle }}
      </el-col>
      <el-col :span="2" :offset="3" class="detailTime">2020-10-21</el-col>
    </el-row>
    <el-row class="newsDetailRow detailMan">
      <el-col :span="16" :offset="3">{{ news.author }}</el-col>
    </el-row>
    <el-row class="body">
      {{ news.newsBody }}
    </el-row>
    <el-row class="newsDetailRow imgRow">
      <el-image
        :src="news.images"
        :preview-src-list="[news.images]"
        v-if="news.images"
        class="newsImg"
      />
      <el-image
        v-else
        :src="rocketUrl"
        :preview-src-list="rocketList"
        class="newsImg"
      />
    </el-row>
    <el-row class="newsDetailRow control">
      <el-col :span="2">
        <span
          class="navigator"
          :class="{ blueColor: hasPre }"
          @click="changeBefore"
        >上一篇</span
        >
      </el-col>
      <el-col :span="2" :offset="20">
        <span
          class="navigator"
          :class="{ blueColor: hasNext }"
          @click="changeNext"
        >下一篇</span
        >
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import { getnewsdetail } from "../api/news.js";
import { ref } from "@vue/reactivity";

export default {
  components: {
    Footer,
  },
  setup() {
    // 正常的图片
    const rocketUrl = ref(require("@/assets/photo/news/rocket.png"));
    // 点击后的图片
    const rocketList = ref([require("@/assets/photo/news/rocket.png")]);
    return {
      rocketUrl,
      rocketList,
    };
  },
  data() {
    return {
      id: "",
      news: null,
      nextPage: undefined, // 上下一页页码
      beforePage: undefined,
    };
  },
  computed: {
    hasNext() {
      return this.nextPage;
    },
    hasPre() {
      return this.beforePage;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getNews();
  },

  methods: {
    setDocumentTitle() {
      document.title = this.news.newsTitle;
      // this.title = this.news.newsTitle
      console.log(document.title);
    },
    async getNews() {
      const res = await getnewsdetail(this.id);
      console.log(res);
      this.news = res.data[0];
      this.beforePage = res.pagination.beforePage;
      this.nextPage = res.pagination.nextPage;

      // console.log('前面');
      this.setDocumentTitle();
    },
    changeBefore() {
      if (this.beforePage) {
        this.$router.push({
          path: "/Newsdetail",
          query: {
            id: this.beforePage,
          },
        });
      }
    },
    changeNext() {
      if (this.nextPage) {
        this.$router.push({
          path: "/Newsdetail",
          query: {
            id: this.nextPage,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.blueColor {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: black !important;
}

.titleRow {
  padding-top: 59px;
}

.el-divider--horizontal {
  width: 1140px;
  height: 1px;
  background: #999999;
  margin: 19px 398px 100px;
}

.detailTitle {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 7px;
  text-align: center;
}

.detailTime {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  line-height: 60px;
}

.detailMan {
  /* height: 50px; */
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  padding: 18px 0 39px 0;
}

.newsDetailRow {
  width: 1140px;
  margin-left: 21%;
  text-align: center;
}

.body {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 1140px;
  margin-left: 21%;
  line-height: 30px;
  letter-spacing: 2px;
  text-indent: 40px;
  text-align: justify;
}

.imgRow {
  display: flex;
  justify-content: center;
  margin-top: 39px;
}

.control {
  height: 20px;
  line-height: 20px;
  margin-top: 49px;
}

.navigator {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  cursor: pointer;
  color: #999999;
}
</style>
