<template>
  <div @click="showNews" class="headerTitle">新闻动态</div>
</template>
<script>
export default {
  methods: {
    showNews() {
      this.$router.push("/Newsinformation");
    },
  },
};
</script>
<style scoped>
.headerTitle {
  width: 84px;
  cursor: pointer;
}
</style>
