<template>
  <div class="cooperationSwiper">
    <div class="swiper-father cooperation-father">
      <div class="swiper-container cooperation">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation1.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation2.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation3.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation4.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation5.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation6.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation7.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation8.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation9.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation10.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation11.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation12.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation13.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation14.png" />
          </div>

          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation15.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation16.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation17.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation18.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation19.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation20.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation21.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation22.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation23.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation24.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation25.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation26.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation27.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation28.png" />
          </div>
          <div class="swiper-slide">
            <img src="../../assets/photo/Aboutus/cooperation29.png" />
          </div>
        </div>
      </div>
      <div class="swiper-button-prev prevCooperation"></div>
      <div class="swiper-button-next nextCooperation"></div>
    </div>
  </div>
</template>
<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper";
export default {
  data() {
    return {
    };
  },
  mounted() {
    new Swiper(".cooperation", {
      autoplay: false,
      // observer: true,
      observeParents: true,
      slidesPerColumnFill: "row",
      slidesPerView: 7,
      slidesPerColumn: 2,
      navigation: {
        nextEl: ".nextCooperation",
        prevEl: ".prevCooperation",
      },
    });
    // console.log(cooperationSwiper);
  },
};
</script>
<style scoped>
.cooperationSwiper {
  height: 240px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.cooperation-father {
  width: 1196px;
  position: relative;
}
.swiper-container {
  width: 90%;
  height: 100%;
}
.swiper-slide {
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>
