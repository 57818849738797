<template>
  <div class="HeaderPage">
    <div class="headerLine">
      <div class="logo-img">
        <img src="@/assets/photo/header/header-logo.png" @click="clickIndex" />
      </div>

      <div class="header-text">
        <el-row class="menuRow" @mouseleave="isHover = false">
          <!--@mouseleave="isHover = false">-->
          <el-col :span="2" :offset="7" class="menuTitle">
            <indexComponent
              @click="hideProductOptions"
              class="headerWords"
              @mouseenter="isHover = false"
            ></indexComponent>
            <!--@mouseenter="isHover = false"-->
          </el-col>

          <el-col :span="3" class="menuTitle" @mouseenter="isHover = true">
            <productComponent
              class="headerWords"
              :style="active"
            ></productComponent>
          </el-col>

          <el-col :span="3" class="menuTitle">
            <newsComponent
              @click="hideProductOptions"
              @mouseenter="isHover = false"
              class="headerWords"
            ></newsComponent>
          </el-col>

          <el-col :span="3" class="menuTitle">
            <recruitComponent
              @click="hideProductOptions"
              class="headerWords"
            ></recruitComponent>
          </el-col>

          <el-col :span="3" class="menuTitle">
            <aboutComponent
              @click="hideProductOptions"
              class="headerWords"
            ></aboutComponent>
          </el-col>

          <div class="suspension" v-if="isHover" @mouseover="over" @mouseout="out">
            <el-row class="suspensionRow">
              <el-col :span="17">
                <el-row class="suspensionTitle" @click="isHover = false"
                  >产品中心
                </el-row>
                <el-row
                  @mouseover="overOneEle"
                  @mouseout="leaveOneEle"
                  @click="clickOneProduct"
                >
                  <el-col :span="6">
                    <div class="systemTitle Guidance">智能导诊系统</div>
                    <div class="systemTitle Consultation">智能预问诊系统</div>
                    <div class="systemTitle Ultrasonic">
                      人工智能语音超声、放射系统
                    </div>
                    <div class="systemTitle Medicalrecord">
                      智能语音病历系统
                    </div>
                    <div class="systemTitle Operation">安全决策手术系统</div>
                  </el-col>

                  <el-divider direction="vertical"></el-divider>

                  <el-col :span="6" class="systemTitles">
                    <div class="systemTitle Nursing">智能护理交互室系统</div>
                    <div class="systemTitle Followup">AI随访系统</div>
                    <div class="systemTitle Firstaid">5G急救系统</div>
                    <div class="systemTitle Coordination">
                      5G多院区急诊移动协同系统
                    </div>
                    <div class="systemTitle Pathology">5G远程冰冻病理诊断</div>
                  </el-col>

                  <el-divider direction="vertical"></el-divider>

                  <el-col :span="6" class="systemTitles">
                    <div class="systemTitle Rose">5G远程ROSE病理评估</div>
                    <div class="systemTitle Brain">肿瘤辅助手术规划系统</div>
                    <div class="systemTitle Narcoticsystem">
                      智能药箱药柜系统
                    </div>
                    <div class="systemTitle Wisdom">智慧病理</div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="7" class="suspensionCol">
                <div class="image-container">
                  <img :src="productImage" />

                  <!--                  <img src="@/assets/photo/header/product.png"/>-->
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import indexComponent from "@/components/PageJump/indexComponent.vue";
import aboutComponent from "@/components/PageJump/aboutComponent.vue";
import productComponent from "@/components/PageJump/productComponent.vue";
import newsComponent from "@/components/PageJump/newsComponent.vue";
import recruitComponent from "@/components/PageJump/recruitComponent.vue";

export default {
  components: {
    indexComponent,
    aboutComponent,
    productComponent,
    newsComponent,
    recruitComponent,
    // Breadcrumb,
  },
  data() {
    return {
      isHover: "",
      isShowBreadcrumb: false,

      active: "",
      productImage: require("@/assets/photo/ProductsIntro/Guidance.png"),
      productImages: {
        Guidance: require("@/assets/photo/ProductsIntro/Guidance.png"),
        Consultation: require("@/assets/photo/ProductsIntro/Consultation.png"),
        Ultrasonic: require("@/assets/photo/ProductsIntro/Ultrasonic.png"),
        Medicalrecord: require("@/assets/photo/ProductsIntro/Medicalrecord.png"),
        Operation: require("@/assets/photo/ProductsIntro/Operation.png"),
        Nursing: require("@/assets/photo/ProductsIntro/Nursing.png"),
        Followup: require("@/assets/photo/ProductsIntro/Followup.png"),
        Firstaid: require("@/assets/photo/ProductsIntro/Firstaid.png"),
        Coordination: require("@/assets/photo/ProductsIntro/5gMultiHospital.png"),
        Pathology: require("@/assets/photo/ProductsIntro/5gRemoteDio.png"),
        Rose: require("@/assets/photo/ProductsIntro/5gRose.png"),
        Brain: require("@/assets/photo/ProductsIntro/Brain.png"),
        Narcoticsystem: require("@/assets/photo/ProductsIntro/Narcoticsystem.png"),
        Wisdom: require("@/assets/photo/ProductsIntro/wisdom.png"),
      },
    };
  },

  created() {},
  methods: {
    clickIndex() {
      this.$router.push("/Index/");
    },
    overOneEle(event) {
      if (event.target.classList.contains("systemTitle")) {
        event.target.classList.add("systemTitleSpace");

        this.decideImage(event.target.classList);
      }
    },
    leaveOneEle(event) {
      if (event.target.classList.contains("systemTitle")) {
        event.target.classList.remove("systemTitleSpace");
      }
    },
    decideImage(classList) {
      console.log("哇哈哈");
      if (classList.contains("Guidance")) {
        this.productImage = this.productImages["Guidance"];
      } else if (classList.contains("Consultation")) {
        this.productImage = this.productImages["Consultation"];
      } else if (classList.contains("Ultrasonic")) {
        this.productImage = this.productImages["Ultrasonic"];
      } else if (classList.contains("Medicalrecord")) {
        this.productImage = this.productImages["Medicalrecord"];
      } else if (classList.contains("Operation")) {
        this.productImage = this.productImages["Operation"];
      } else if (classList.contains("Nursing")) {
        this.productImage = this.productImages["Nursing"];
      } else if (classList.contains("Followup")) {
        this.productImage = this.productImages["Followup"];
      } else if (classList.contains("Firstaid")) {
        this.productImage = this.productImages["Firstaid"];
      } else if (classList.contains("Coordination")) {
        this.productImage = this.productImages["Coordination"];
      } else if (classList.contains("Pathology")) {
        this.productImage = this.productImages["Pathology"];
      } else if (classList.contains("Rose")) {
        this.productImage = this.productImages["Rose"];
      } else if (classList.contains("Brain")) {
        this.productImage = this.productImages["Brain"];
      } else if (classList.contains("Narcoticsystem")) {
        this.productImage = this.productImages["Narcoticsystem"];
      } else if (classList.contains("Wisdom")) {
        this.productImage = this.productImages["Wisdom"];
      } else {
        this.productImage = this.productImages["Narcoticsystem"];
        console.log("悬浮出现故障");
      }
    },

    clickOneProduct(event) {
      // this.isHover = false
      this.hideProductOptions();
      const classList = event.target.classList;
      if (classList.contains("systemTitle")) {
        if (classList.contains("Guidance")) {
          this.$router.push("/Guidance");
        } else if (classList.contains("Consultation")) {
          this.$router.push("/Consultation");
        } else if (classList.contains("Ultrasonic")) {
          this.$router.push("/Ultrasonic");
        } else if (classList.contains("Medicalrecord")) {
          this.$router.push("/Medicalrecord");
        } else if (classList.contains("Operation")) {
          this.$router.push("/Operation");
        } else if (classList.contains("Nursing")) {
          this.$router.push("/Nursing");
        } else if (classList.contains("Followup")) {
          this.$router.push("/Followup");
        } else if (classList.contains("Firstaid")) {
          this.$router.push("/Firstaid");
        } else if (classList.contains("Coordination")) {
          this.$router.push("/Coordination");
        } else if (classList.contains("Pathology")) {
          this.$router.push("/Pathology");
        } else if (classList.contains("Rose")) {
          this.$router.push("/Rose");
        } else if (classList.contains("Brain")) {
          this.$router.push("/Brain");
        } else if (classList.contains("Narcoticsystem")) {
          this.$router.push("/Narcoticsystem");
        } else if (classList.contains("Wisdom")) {
          this.$router.push("/Wisdom");
        } else {
          this.productImage = this.productImages["Narcoticsystem"];
          console.log("跳转出现故障");
        }
      }
    },

    over() {
      this.active = "border-bottom: 8px solid #0092de";
    },
    out() {
      this.active = "";
    },
    hideProductOptions() {
      this.isHover = false;
    },
  },
};
</script>

<style scoped lang="scss">
.systemTitleSpace {
  padding-left: 6px;
}

.headerWords {
  height: 90px;
}

.headerWords:hover {
  border-bottom: 8px solid #0092de;
  color: #0092de;
}

.HeaderPage {
  width: 100%;
  height: 98px;
  background: #fbfbfb;
  box-shadow: 0px 0px 6px 0px rgba(91, 91, 91, 0.5);
}

.headerLine {
  height: 100%;
  width: 1175px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.logo-img {
  width: 318px;
  height: 72px;
  padding-top: 15px;
  float: left;
  cursor: pointer;
}

.header-text {
  width: 849px;
  height: 100%;
  float: right;
}

.menuRow {
  height: 100%;
  align-items: center;
}

.menuTitle {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 3px;
  height: 100%;
  line-height: 100px;
  margin-right: 20px;
}

.suspension {
  width: 1920px;
  height: 240px;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 98px;
  left: -705px;
  box-shadow: 0px 4px 6px -5px rgba(91, 91, 91, 0.5)inset;
}

.suspensionRow {
  width: 60%;
  height: 100%;
}

.suspensionCol {
  display: flex;
  align-items: center;
}

.suspensionTitle {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 20%;
  line-height: 45px;
  letter-spacing: 3px;
}

.systemTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 1px;
  line-height: 35px;
  cursor: pointer;
}

.systemTitle:hover {
  color: #0092de;
}

.el-divider--vertical {
  width: 1px;
  height: 150px;
  background: #cccccc;
  margin: 11px 20px;
}

.image-container {
  width: 320px;
  //height: 200px;
  height: 170px;

  img {
    height: 100%;
    width: auto;
  }
}
</style>
