<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">5G多院区急诊移动协同</div>
      <div class="coordinationWords">
        总院 -
        多院区之间协同急诊，让多急诊科和移动端同时看到患者体征、高清视频等，共享急诊医生资源，不受距离、交通等的限制。
      </div>
      <el-row class="characteristicsRow">
        <el-col :span="7" class="characteristicsCol">
          <div class="characteristicsTitle docking">
            急诊科与院内系统的无缝信息对接
          </div>
          <div class="subject dockingText">
            <el-row v-for="item in dockData" :key="item" class="alignment">
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="23">{{ item.dockText }}</el-col>
            </el-row>
          </div>
          <img src="@/assets/photo/coordination/photo1.png" alt="" />
        </el-col>
        <el-col :span="7" :pull="1" class="characteristicsCol">
          <div class="characteristicsTitle realization">
            利用5G网关技术和优享切片技术实现
          </div>
          <div class="subject technologyText">
            <el-row
              v-for="technology in technologyData"
              :key="technology"
              class="alignment"
            >
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="23">{{ technology.technologyText }}</el-col>
            </el-row>
          </div>
          <img src="@/assets/photo/coordination/photo2.png" alt="" />
        </el-col>
        <el-col :span="7" :pull="2" class="characteristicsCol">
          <div class="characteristicsTitle aav">音视频功能</div>
          <div class="subject aavText">
            <el-row v-for="aav in aavData" :key="aav" class="alignment">
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="23">{{ aav.aavText }}</el-col>
            </el-row>
          </div>
          <img src="@/assets/photo/coordination/photo3.png" alt="" />
        </el-col>
      </el-row>
      <el-row class="monitoringRow">
        <el-col :span="2" class="animation hideElement">
          <div class="monitoringEquipment">
            <span class="equipmentWords">监护设备</span>
          </div>
        </el-col>
        <el-col :span="2" class="animation hideElement">
          <img src="@/assets/photo/coordination/arrow.png" alt="" />
        </el-col>
        <el-col :span="5" class="animation hideElement">
          <div class="buleBorder">
            <el-row class="borderRow">
              <el-image
                :src="mdsUrl"
                :preview-src-list="mdsList"
                append-to-body
                class="mds"
              />
              <span class="second shift">MDS服务器</span>
            </el-row>
            <el-row class="borderRow">
              <el-image
                :src="bigUrl"
                :preview-src-list="screenList"
                append-to-body
              />
              <span class="second shift">大屏显示</span>
            </el-row>
            <el-row class="first borderRow">急诊科</el-row>
          </div>
        </el-col>
        <el-col :span="3" class="animation hideElement">
          <el-row class="centerRow">
            <img src="@/assets/photo/coordination/top.png" alt="" />
          </el-row>
          <el-row class="centerRow middleRow">
            <img src="@/assets/photo/coordination/center.png" alt="" />
          </el-row>
          <el-row class="centerRow">
            <img src="@/assets/photo/coordination/bottom.png" alt="" />
          </el-row>
        </el-col>
        <el-col :span="6" class="animation hideElement">
          <div class="blueBox">
            <el-row class="tool">
              <el-col :span="7" :offset="2">
                <el-image
                  :src="phoneUrl"
                  :preview-src-list="phoneList"
                  append-to-body
                />
                <div class="second">手机</div>
              </el-col>
              <el-col :span="9" :offset="4">
                <el-image
                  :src="padUrl"
                  :preview-src-list="padList"
                  append-to-body
                />
                <div class="second">Pad</div>
              </el-col>
            </el-row>
            <el-row class="tertiary">远程急救专家</el-row>
          </div>

          <div class="noBorder">
            <el-row class="borderRow">
              <el-image
                :src="fiveUrl"
                :preview-src-list="mdsList"
                append-to-body
              />
              <span class="second shift server">5G服务器</span>
            </el-row>
            <el-row class="borderRow">
              <el-image
                :src="screenUrl"
                :preview-src-list="screenList"
                append-to-body
              />
              <span class="second shift">大屏显示</span>
            </el-row>
            <el-row class="first borderRow">多院区急诊中心</el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { ref } from "@vue/reactivity";
export default {
  components: {
    Footer,
  },
  setup() {
    //正常的图片
    const mdsUrl = ref(require("@/assets/photo/coordination/MDS.png"));
    const bigUrl = ref(require("@/assets/photo/coordination/bigScreen.png"));
    const phoneUrl = ref(require("@/assets/photo/coordination/phone.png"));
    const padUrl = ref(require("@/assets/photo/coordination/Pad.png"));
    const fiveUrl = ref(require("@/assets/photo/coordination/5g.png"));
    const screenUrl = ref(require("@/assets/photo/coordination/screen.png"));
    //点击后的图片
    const mdsList = ref([
      require("@//assets/photo/el-img/Coordination/el-MDS.png"),
    ]);
    const screenList = ref([
      require("@//assets/photo/el-img/Coordination/el-bigScreen.png"),
    ]);
    const phoneList = ref([
      require("@//assets/photo/el-img/Coordination/el-phone.png"),
    ]);
    const padList = ref([
      require("@//assets/photo/el-img/Coordination/el-Pad.png"),
    ]);
    return {
      mdsUrl,
      bigUrl,
      phoneUrl,
      padUrl,
      fiveUrl,
      screenUrl,

      mdsList,
      screenList,
      phoneList,
      padList,
    };
  },
  data() {
    return {
      dockData: [
        { dockText: "生命体征数据自动记录" },
        { dockText: "急诊记录的智能生成" },
        { dockText: "患者数据无缝转交接诊医生" },
        { dockText: "与院内急诊科信息集成对接" },
      ],
      technologyData: [
        { technologyText: "急诊场景实况高清传输到多院区急诊中心" },
        { technologyText: "患者急救数据高清传输到远程医生移动端" },
        { technologyText: "音视频沟通和交流" },
        { technologyText: "毫秒级实时通讯" },
      ],
      aavData: [
        { aavText: "支持手机+Pad视频监控、回传、伴音控制" },
        { aavText: "视频调度:终端到诊断的视频推送" },
        { aavText: "音频调度:终端到终端、调度台的全双工语音" },
      ],
    };
  },
  mounted() {
    this.playAnimation();
  },
  methods: {
    playAnimation() {
      const eles = document.getElementsByClassName("animation");
      eles[0].classList.add("fade-in");
      setTimeout(() => {
        eles[1].classList.add("fade-in");
        setTimeout(() => {
          eles[2].classList.add("fade-in");
          setTimeout(() => {
            eles[3].classList.add("fade-in");
            setTimeout(() => {
              eles[4].classList.add("fade-in");
            }, 500);
          }, 200);
        }, 200);
      }, 500);
    },
  },
};
</script>
<style scoped>
.server {
  margin-left: 33px !important;
}
.mds {
  margin-left: 29px;
}
.noBorder {
  margin-top: 105px;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  /*结束状态 透明度为1*/
}

/* .tableLimit tr td .cell {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */

.hideElement {
  opacity: 0;
}

.fade-in {
  animation: fade-in; /*动画名称*/
  animation-duration: 1.5s; /*动画持续时间*/
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.oneRow {
  height: 1134px;
}
.coordinationWords {
  width: 789px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 3px;
  margin-left: 29%;
  margin-top: 38px;
  text-align: justify;
}

.characteristicsRow {
  margin-top: 31px;
  margin-left: 24%;
  height: 19%;
}
.characteristicsCol {
  margin-top: 30px;
}
.equipmentRow {
  margin-top: 46px;
}
.equipment {
  width: 630px;
  height: 50px;
  background: #0092de;
  border-radius: 25px;

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  text-align: center;
  line-height: 50px;
  letter-spacing: 3px;
}
.characteristicsTitle {
  width: 161px;
  height: 42px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 2px;
  position: absolute;
  text-align: center;
}
.docking {
  margin-left: 52px;
  margin-top: -20px;
}
.realization {
  width: 159px;
  margin-top: -20px;
  margin-left: 58px;
}
.aav {
  width: 93px;
  margin-top: -10px;
  margin-left: 90px;
}
.point {
  width: 6px;
  height: 6px;
  background: #98d1f3;
  position: absolute;
  margin-top: 7px;
  margin-left: -6px;

  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.subject {
  width: 201px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  position: absolute;
}
.dockingText {
  margin-top: 35px;
  margin-left: 37px;
  text-align: justify;
}
.technologyText {
  margin-left: 40px;
  margin-top: 36px;
  text-align: justify;
}
.aavText {
  margin-left: 50px;
  margin-top: 34px;
  text-align: justify;
}
.alignment {
  margin-top: 2px;
}

.monitoringRow {
  width: 80%;
  height: 58%;
  margin-left: 20%;
  display: flex;
  align-items: center;
}
.monitoringEquipment {
  width: 100px;
  height: 100px;
  background: #98d1f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.equipmentWords {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fbfbfb;
  letter-spacing: 3px;
  width: 42px;
}
.second {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  text-align: center;
}
.first {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 2px;
  margin-top: 6%;
}
.tertiary {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0092de;
  letter-spacing: 2px;
  justify-content: center;
  margin-top: 3%;
}
.buleBorder {
  width: 300px;
  height: 300px;
  border: 1px dashed #0092de;
  margin-left: 14px;
}
.blueBox {
  width: 360px;
  height: 180px;
  border: 1px dashed #0092de;
  /* margin-top: 34%; */
}
.borderRow {
  justify-content: center;
  align-items: center;
  margin-top: 9%;
}
.centerRow {
  justify-content: center;
}
.middleRow {
  margin: 40px 0;
}
.tool {
  margin-top: 6%;
}
.shift {
  margin-left: 20px;
}
</style>
