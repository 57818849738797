<template>
  <div class="circular">
    <span class="text">{{ item }}</span>
  </div>
</template>
<script>
export default {
  // props: ["item"],
  props: {
    item: String,
  },
};
</script>
<style scoped>
.circular {
  width: 200px;
  height: 200px;
  background: linear-gradient(-45deg, #2aa7e8 0%, #6dc2ee 100%);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}
.text {
  width: 138px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  letter-spacing: 3px;
}
</style>
