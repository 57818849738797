<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">智能导诊系统</div>
      <div class="sketchText">
        <div class="words wordsRow">
          通过多轮智能人机对话、人体图和选择项了解患者病情，为患者推荐最合适的科室与医生。
        </div>
      </div>
      <div class="sketchImg">
        <div class="animation hideElement">
          <!-- <img src="@/assets/photo/zndz/resources1.png" class="" /> -->
          <el-image
            :src="resourcesUrl"
            :preview-src-list="resourcesList"
            append-to-body="true"
          />
          <div class="explain">病情描述</div>
        </div>

        <div class="analysis animation hideElement">
          <img
            src="@/assets/photo/zndz/arrow.png"
            class="arrow animation hideElement"
          />
          <img
            src="@/assets/photo/zndz/arrow.png"
            class="arrow animation hideElement"
          />
          <img
            src="@/assets/photo/zndz/arrow.png"
            class="arrow animation hideElement"
          />
          <div class="explain">智能分析</div>
        </div>

        <div class="animation hideElement">
          <!-- <img
            src="@/assets/photo/zndz/resources2.png"
            class=""
          /> -->
          <el-image
            :src="resources2Url"
            :preview-src-list="resources2List"
            append-to-body="true"
          />
          <div class="explain">推荐科室</div>
        </div>
      </div>
    </div>

    <div class="functionalArea towRow">
      <el-table
        :data="tableData"
        border
        style="width: 800px"
        class="tableLimit"
      >
        <el-table-column prop="modular" label="功能模块" width="139" />

        <el-table-column label="功能概述">
          <template #default="scope">
            <el-row class="row-container">
              <span class="point"></span>
              <span style="margin-left: 10px">{{ scope.row.summary }}</span>
            </el-row>
            <el-row v-if="scope.row.summaryTwo" class="row-container">
              <span class="point"></span>
              <span style="margin-left: 10px">{{ scope.row.summaryTwo }}</span>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { ref } from "@vue/reactivity";

export default {
  setup() {
    //正常的图片
    const resourcesUrl = ref(require("@/assets/photo/zndz/resources1.png"));
    const resources2Url = ref(require("@/assets/photo/zndz/resources2.png"));
    //点击后的图片
    const resourcesList = ref([
      require("@/assets/photo/el-img/Guidance/el-resources1.png"),
    ]);
    const resources2List = ref([
      require("@/assets/photo/el-img/Guidance/el-resources2.png"),
    ]);
    return {
      resourcesUrl,
      resources2Url,
      resourcesList,
      resources2List
    };
  },
  data() {
    return {
      tableData: [
        {
          modular: "口述资料库",
          summary: "通过海量医疗数据支撑，建立口述资料知识库；",
          summaryTwo: "支持口述资料的自主收集和学习；",
        },
        {
          modular: "疾病知识库",
          summary: "通过海量医疗数据支撑，建立疾病/病情知识库；",
          summaryTwo: "支持关于疾病/病情资料的自主收集和学习；",
        },
        {
          modular: "模板库管理",
          summary:
            "提供标准化的问答式交互管理服务，对所有的问答式交互模板进行集中分类管理；",
          summaryTwo: "支持各类型问答式交互模板的自主设定和个性化设置；",
        },
        {
          modular: "就诊指南",
          summary: "就诊流程、医保提示等；",
        },
        {
          modular: "主诉输入",
          summary: "支持患者自然语言输入；",
          summaryTwo: "支持对应标准症状疾病名称；",
        },
        {
          modular: "关联症状补选",
          summary: "系统智能推荐可能症状，患者可再次点选确认；",
        },
        {
          modular: "智能分科推荐",
          summary: "给出最佳就诊方案，提高患者就效率；",
        },
      ],
      // playElement: 0,
    };
  },
  components: {
    Footer,
  },
  mounted() {
    this.playAnimation();
  },
  methods: {
    playAnimation() {
      const eles = document.getElementsByClassName("animation");
      eles[0].classList.add("fade-in");
      setTimeout(() => {
        eles[1].classList.add("fade-in");
        setTimeout(() => {
          eles[2].classList.add("fade-in");
          setTimeout(() => {
            eles[3].classList.add("fade-in");
            setTimeout(() => {
              eles[4].classList.add("fade-in");
              setTimeout(() => {
                eles[5].classList.add("fade-in");
                setTimeout(() => {
                  eles[6].classList.add("fade-in");
                }, 500);
              }, 500);
            }, 500);
          }, 200);
        }, 200);
      }, 500);
    },
  },
};
</script>

<style scoped>
.cell {
  /* flex-direction: column; */
}
.row-container {
  align-items: center;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  /*结束状态 透明度为1*/
}

/* .tableLimit tr td .cell {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */

.tableLimit {
  margin: 60px 0 100px 0;
}
.hideElement {
  opacity: 0;
}

.fade-in {
  animation: fade-in; /*动画名称*/
  animation-duration: 1.5s; /*动画持续时间*/
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.oneRow {
  height: 735px;
}

.towRow {
  height: 641px;
}

.wordsRow {
  width: 680px;
}

.arrow {
  width: 30px;
  height: 52px;
}

.point {
  width: 8px;
  height: 8px;
  background: #0092de;
  border-radius: 50%;
}
.analysis {
  margin: 0 30px;
}

</style>

<style>
.functionalArea .el-table {
  --el-table-border-color: #999999;
  color: #333333;
}

.functionalArea .el-table .el-table__cell {
  background: #f5f5f7;
}

.functionalArea .el-table .cell {
  display: flex;
  align-items: left;
  flex-direction: column;
}

.functionalArea .el-table th.el-table__cell > .cell {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  letter-spacing: 3px;
}

.functionalArea .el-table__body {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 1px;
}

.functionalArea .el-table--border .el-table__cell:first-child .cell {
  text-align: center;
}
</style>
