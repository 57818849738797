<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">AI随访系统</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统特点</el-col>
      </el-row>
      <el-row class="characteristicsRow">
        <el-col
          :span="6"
          :offset="1"
          v-for="item in characteristicsData"
          :key="item"
          class="bottomCol"
        >
          <el-row class="characteristics">
            <el-col :span="1">
              <img src="@/assets/photo/followup/cir.png" alt="" />
            </el-col>
            <el-col :span="19" class="characteristicsCol">{{
              item.text
            }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div class="twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统功能</el-col>
      </el-row>

      <el-row class="domainRow">
        <el-col :span="1" :offset="5" class="domainTitle">随访管理 </el-col>
        <el-col :span="6" class="domainCol">
          <el-row v-for="item in domainData" :key="item" class="wordsRow">
            <el-col :span="1">
              <span class="point"></span>
            </el-col>
            <el-col :span="19" class="functionText">{{
              item.domainText
            }}</el-col>
          </el-row>
        </el-col>

        <el-col :span="1" :offset="1" class="titleCol">
          <el-row class="domainTitle patientT">患者管理</el-row>
          <el-row class="domainTitle knowledgeT">随访知识库管理</el-row>
          <el-row class="domainTitle statisticalT">统计管理</el-row>
          <el-row class="domainTitle systemColT">系统管理</el-row>
        </el-col>

        <el-col :span="6">
          <div class="patient">
            <el-row
              v-for="patient in managementData"
              :key="patient"
              class="textRow"
            >
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="19" class="functionText">{{
                patient.managementText
              }}</el-col>
            </el-row>
          </div>
          <el-divider></el-divider>

          <div class="knowledge">
            <el-row
              v-for="knowledge in baseData"
              :key="knowledge"
              class="textRow"
            >
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="19" class="functionText">{{
                knowledge.baseText
              }}</el-col>
            </el-row>
          </div>
          <el-divider></el-divider>

          <div class="statistical">
            <el-row
              v-for="statistical in administerData"
              :key="statistical"
              class="textRow"
            >
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="19" class="functionText">{{
                statistical.administerText
              }}</el-col>
            </el-row>
          </div>
          <el-divider></el-divider>

          <div class="systemCol">
            <el-row
              v-for="system in superviseData"
              :key="system"
              class="textRow"
            >
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="19" class="functionText">{{
                system.superviseText
              }}</el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      characteristicsData: [
        {
          text: "无间断、全覆盖随访，一天内可完成400-1000人次的随访工作",
        },
        {
          text: "根据不同术种、疾病、用药患者的出院记录制订个性化随访计划",
        },
        {
          text: "灵活的患者筛选，科研随访",
        },
        {
          text: "宣教推送、满意度调查",
        },
        {
          text: "语音合成技术和真人语音库，让交互发声更为自然",
        },
        {
          text: "OCR识别技术，可以识别纸质检验报告并进行结构化处理",
        },
        {
          text: "具有深度学习算法，通过不断地学习来强化AI大脑的认识",
        },
        {
          text: "全自动随访，并且出现异常反馈自动推送至医生与护士端",
        },
        {
          text: "随访过程全程记录",
        },
        {
          text: "AI电话随访:语音识别+自然语言理解，省时省力，无需人工干预，自动拨打自动转换",
        },
        {
          text: "AI微信随访:根据患者回复，自动发送健康宣教、注意事项内容给患者，让随访更智能，更具温度",
        },
        {
          text: "AI短信随访:文字识别+自然语言理解，省时省力，无需人工干预，自动发送自动转换",
        },
        {
          text: "普通电话随访：可通过电话盒子进行自动软拨号，全程自动录音",
        },
      ],
      domainData: [
        {
          domainText:
            "支持按患者来源、科室、主治医生、用药、手术名等筛选，支持随访、宣教、提醒",
        },
        {
          domainText: "随访方式:电话随访，微信随访，短信随访，AI电话随访",
        },
        {
          domainText: "随访时间:支持随访时间设定，定时点推送随访问卷/表单",
        },
        {
          domainText: "状态过滤:“死亡”的患者自动不纳入随访任务",
        },
        {
          domainText:
            "筛选机制:支持预先设置的随访计划条件，每天定时自动筛选符合条件的患者；支持按手术、疾病、用药情况筛选；支持按比例、数量抽取患者并生成相应的随访任务",
        },
        {
          domainText:
            "微信随访:按随访的设定自动推送随访问卷/表单到患者手机端，患者在手机端填写提交随访结果",
        },
        {
          domainText:
            "短信随访:根据设定的随访规则自动推送随访问卷/表单到患者手机端，患者在手机端填写提交随访结果",
        },
        {
          domainText:
            "电话随访:对患者一键拨号进行随访，过程录音；若患者随访异常，可手动增加下一次随访任务",
        },
        {
          domainText:
            "AI随访:提供真人语音问题库的录入和对照；依随访计划，自动对筛选的患者以电话的方式进行随访，过程录音；若患者随访异常，可自动增加下一次随访任务。可以依据设定的问题触发路径自动推送健康宣教信息",
        },
        {
          domainText:
            "宣教推送:对随访过程中有疑问的患者，可以手动推送健康宣教信息",
        },
      ],
      managementData: [
        { managementText: "对接医院系统" },
        {
          managementText:
            "管理出院患者、门诊患者、住院患者的临床数据、治疗记录",
        },
        { managementText: "构建患者个人随访主页，支持快捷拨打、推送操作" },
      ],
      baseData: [
        {
          baseText:
            "支持多题型（文本填空题，单选题，多选题，矩阵题，图片选择题，下拉选择题，多选输入框题）表单的自定义维护题目及选项内容",
        },
        {
          baseText: "支持维护表单选项异常项，并维护提示信息",
        },
        {
          baseText: "支持维护题目内容通过专科专病数据库自动填充",
        },
        {
          baseText: "支持维护题目之间跳题逻辑",
        },
        {
          baseText:
            "提供宣教内容维护功能，支持图片、视频等多形式进行自主编辑内容，格式，排版",
        },
        {
          baseText:
            "提供提醒短语库、短信知识库、疾病知识库、药品知识库的维护管理",
        },
      ],
      administerData: [
        {
          administerText: "支持按患者科室、随访任务、个人随访统计",
        },
        {
          administerText: "支持随访表单统计，包含表单单题统计",
        },
        {
          administerText: "支持结果打印与导出",
        },
      ],
      superviseData: [
        {
          superviseText: "用户权限设置",
        },
        {
          superviseText: "用户设置",
        },
        {
          superviseText: "角色设置",
        },
        {
          superviseText: "院内科室设置",
        },
      ],
    };
  },
  computed: {},
};
</script>
<style scoped>
.el-divider--horizontal {
  width: 400px;
  background: #999999;
}
.oneRow {
  height: 805px;
}
.twoRow {
  height: 896px;
  background: #f5f5f7;
}
.characteristicsRow {
  width: 68%;
  height: 70%;
  margin-left: 22%;
}
.characteristics {
  height: 85%;
}
.bottomCol {
  margin-bottom: 30px;
}
.characteristicsCol {
  margin-top: 10px;
  margin-left: 3px;
  line-height: 25px;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: justify;
}

.point {
  width: 6px;
  height: 6px;
  background: #0092de;
  border-radius: 50%;
  position: absolute;
  margin-top: 8px
}
.domainRow {
  height: 77%;
}
.domainCol {
  height: 100%;
  border-right: 1px solid #999999;
}
.domainTitle {
  writing-mode: tb;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 6px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.functionText {
  line-height: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: justify;
}
.wordsRow {
  margin-top: 4%;
}
.titleCol {
  height: 100%;
}
.knowledge {
  height: 35%;
}
.patient,.statistical {
  height: 11%;
}
.systemCol {
  height: 18%;
}

.patientT {
  height: 15%;
}
.knowledgeT {
  height: 42%;
}
.statisticalT {
  height: 18%;
}
.systemColT {
  height: 20%;
}

.textRow {
  margin-top: 4px;
}
</style>
