<template>
  <div @click="clickProduct" class="headerTitle">产品中心</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    clickProduct() {
      this.$router.push("/products");
    },
  },
};
</script>
<style scoped>
.headerTitle {
  width: 84px;
  cursor: pointer;
}
</style>
