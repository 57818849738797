<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">智能药箱药柜系统</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统特点</el-col>
      </el-row>

      <el-row class="controlRow">
        <el-col :span="6">
          <div class="box">
            <!-- <img src="@/assets/photo/narcoticsystem/box.png" class="boxImg" /> -->
            <el-image :src="boxUrl" :preview-src-list="boxList"  class="boxImg" />
          </div>
        </el-col>
        <el-col :span="8" :offset="4">
          <div class="controlTitle">药品管控</div>
          <el-row v-for="control in controlData" :key="control">
            <!-- <div class="secondTitle">{{ control.secondTitle }}</div>
            <div class="controlWords">{{ control.controlText }}</div> -->
            <div class="controlWords">{{ control.controlWords }}</div>
          </el-row>
        </el-col>
      </el-row>

      <el-row class="checkRow">
        <el-col :span="5" :offset="3">
          <div class="controlTitle checkTitle">数据核对、便利操作</div>
          <el-row v-for="check in checkData" :key="check">
            <!-- <div class="secondTitle">{{ check.secondTitle }}</div>
             <div class="controlWords">{{ check.controlText }}</div>-->
            <div class="controlWords">{{ check.controlWords }}</div>
          </el-row>
          <div class="controlTitle integrate">数据集成、管理便利</div>
        </el-col>
        <el-col :span="6" :offset="2">
          <div class="medicineCabinet">
            <!-- <img
              src="@/assets/photo/narcoticsystem/medicineCabinet.png"
              class="medicineCabinetImg"
            /> -->
            <el-image :src="medicineCabinetUrl" :preview-src-list="medicineCabinetList"  class="medicineCabinetImg"/>
          </div>
        </el-col>
      </el-row>

      <!-- <el-row class="integrateRow">
        <el-col :span="8">
          <div class="controlTitle">数据集成、管理便利</div>
          <div class="controlWords integrateWords">
            中央管理系统集成药箱、药柜信息，护士可以实时查看药箱药柜数据，定时定点补充药箱，药品出纳在箱皆有登记。
          </div>
        </el-col>
      </el-row> -->

      <el-row class="boxRow">
        <el-col :span="5">
          <div class="boxA">
            <el-row class="titleRow">
              <el-col :span="1" :offset="2">
                <span class="sign"></span>
              </el-col>
              <el-col :span="19" :offset="1" class="boxATitle">
                全流程身份核实和绑定
              </el-col>
            </el-row>
            <el-row class="wordsRow">
              <el-col :span="10" :offset="2" class="boxAWords"
                >1. 领箱人
              </el-col>
              <el-col :span="10" :offset="1" class="boxAWords">2. 医生</el-col>
            </el-row>
            <el-row class="wordsRow">
              <el-col :span="10" :offset="2" class="boxAWords">3. 患者</el-col>
              <el-col :span="10" :offset="1" class="boxAWords"
                >4. 回库人
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="5" class="boxCol">
          <div class="boxA">
            <el-row class="titleB">
              <el-col :span="1" :offset="2">
                <span class="sign"></span>
              </el-col>
              <el-col :span="19" :offset="1" class="boxBTitle">
                每一瓶麻醉药品使用和记录
              </el-col>
            </el-row>
            <el-row class="boxBWords">1. 开箱时药数量核对</el-row>
            <el-row class="boxBWords">2. 出库数量与回库数量的差额</el-row>
            <el-row class="boxBWords"
              >3. 特殊情况：补充数量、一箱多台手术、医生A->医生B
            </el-row>
            <el-row class="boxBWords">4. 关箱时药数量核对</el-row>
          </div>
        </el-col>
        <el-col :span="5" class="boxCol">
          <div class="boxA">
            <el-row class="titleC">
              <el-col :span="1" :offset="3">
                <span class="sign"></span>
              </el-col>
              <el-col :span="19" :offset="1" class="boxATitle">
                高资耗材的记录
              </el-col>
            </el-row>
            <el-row class="titleC">
              <el-col :span="1" :offset="3">
                <span class="sign"></span>
              </el-col>
              <el-col :span="19" :offset="1" class="boxATitle">
                对接手麻+药房系统（按需要）
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>

      <div class="solveTitle">可以解决的问题</div>
      <el-row class="solveRow">
        <el-col :span="4">
          <img src="@/assets/photo/narcoticsystem/ring.png" class="solveImg" />
          <div class="record">
            <div>安全</div>
            <div>全程跟踪</div>
            <div>准确记录</div>
          </div>
        </el-col>
        <el-col :span="14" :offset="2">
          <el-row>
            <el-col :span="4">
              <div class="diamond diamondTop"></div>
              <span class="diamondWords spanThree">麻精药、耗材全程跟踪</span>
            </el-col>
            <el-col :span="4">
              <div class="diamond diamondBtm"></div>
              <span class="diamondWords secondWords">存储管理</span>
            </el-col>
            <el-col :span="4">
              <div class="diamond diamondTop"></div>
              <span class="diamondWords spanFour">手术台清台结</span>
            </el-col>
            <el-col :span="4">
              <div class="diamond diamondBtm"></div>
              <span class="diamondWords secondWords">双核销毁</span>
            </el-col>
          </el-row>

          <el-row class="diamondTwo">
            <el-col :span="4">
              <div class="diamond"></div>
              <span class="diamondWords spanFour">全程医生信息核实</span>
            </el-col>
            <el-col :span="4" :offset="4">
              <div class="diamond"></div>
              <span class="diamondWords spanFive"
                >交接班、批号 、 工作记录管理
              </span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <!-- <div class="twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">界面展示</el-col>
      </el-row>
      <el-row class="showRow">
        <el-col :span="7" :offset="1">
          <img src="@/assets/photo/narcoticsystem/chest.png" alt="" />
        </el-col>
        <el-col :span="5" :offset="2">
          <div class="showTitle">药箱系统</div>
          <div class="showWords">
            信息核对，除了能够通过软件显示在电脑上，药箱也有对应的内容显示；
          </div>
          <div class="showWords">
            销毁录像，录像销毁监控，可实时双人双核，有强制身份验证的功能。
          </div>
        </el-col>
      </el-row>
      <el-row class="showRow">
        <el-col :span="5" :offset="2">
          <div class="showTitle">药柜系统</div>
          <div class="showWords">
            权限不同，操作不同，提供专人专属 管理，提供术后红处方用药打印。
          </div>
        </el-col>
        <el-col :span="6" :offset="2">
          <img src="@/assets/photo/narcoticsystem/cabinet.png" alt="" />
        </el-col>
      </el-row>
      <el-row class="showRow">
        <el-col :span="8" :offset="1">
          <img src="@/assets/photo/narcoticsystem/main.png" alt="" />
        </el-col>
        <el-col :span="5" :offset="1">
          <div class="showTitle">中央管理系统</div>
          <div class="showWords">
            集成药箱药柜数据，可查双人双核录 像，用药信息追踪。
          </div>
        </el-col>
      </el-row>
    </div> -->

    <!-- <div class="threeRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol animationHeader" :span="3"
          >使用流程</el-col
        >
      </el-row>

      <div class="use useItem" v-for="part in processData" :key="part">
        <div class="process animation hideElement">
          <span class="processWords">{{ part.process }}</span>
        </div>
        <div class="triangle animation hideElement" v-if="part.process">
          <img src="@/assets/photo/narcoticsystem/triangle.png" alt="" />
        </div>
      </div>
    </div> -->

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { ref } from "@vue/reactivity";

export default {
  components: {
    Footer,
  },
  setup() {
    //正常的图片
    const boxUrl = ref(require("@/assets/photo/narcoticsystem/box.png"));
    const medicineCabinetUrl = ref(require("@/assets/photo/narcoticsystem/medicineCabinet.png"));
    //点击后的图片
    const boxList = ref([require("@/assets/photo/el-img/Narcoticsystem/el-box.png")]);
    const medicineCabinetList = ref([require("@/assets/photo/el-img/Narcoticsystem/el-medicineCabinet.png")]);
    return {
      boxUrl,
      boxList,
      medicineCabinetUrl,
      medicineCabinetList,
    };
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    console.log("销毁组件");
    clearInterval(this.timer);
    this.timer = null;
    next();
  },

  mounted() {
    this.timer = setInterval(() => {
      this.getHeight();
    }, 1000);
  },
  data() {
    return {
      timer: null,
      controlData: [
        {
          // secondTitle: "1.生物识别——药品溯源",
          controlWords: "1.生物识别——药品溯源",
        },
        {
          // secondTitle: "2.双人双锁，双人双核",
          controlWords: "2.双人双锁，双人双核",
          // controlText:
          //   "在销毁过程中，药箱自带视频录像，身份核实、资格验证，并记录一类精神药品的销毁过程。",
        },
        {
          // secondTitle: "3.药物分开管理",
          controlWords: "3.药物分开管理",
        },
        {
          // secondTitle: "4.高资耗材存放",
          controlWords: "4.高资耗材存放",
        },
      ],
      checkData: [
        {
          // secondTitle: "1.数据核对",
          controlWords: "1.数据核对  ",
        },
        {
          // secondTitle: "2.配药便利",
          controlWords: "2.配药便利 ",
        },
        {
          // secondTitle: "3.处方单",
          controlWords: "3.处方单  ",
        },
      ],
      // processData: [
      //   {
      //     process:
      //       "医生通过账户或生物识别获得操作药柜权限，根据手术量，合理取用药箱",
      //   },
      //   {
      //     process:
      //       "药箱与手术麻醉系统进行连接与确认，完成手术后，进行数据的核查校正",
      //   },
      //   {
      //     process:
      //       "一类精神药品在开箱情况下，邀请第二人一起进行药液的销毁以及药瓶的回收。医生自行判断，若药量充足，则可用于下一台手术",
      //   },
      //   {
      //     process: "医生归还药箱，并打印红处方单，完成验证及签名",
      //   },
      //   {
      //     process:
      //       "护士根据系统信息或提示，定时定点到对应的药柜前，进行药箱替换或取回",
      //   },
      //   {
      //     process:
      //       "护士按照系统设置的药品信息摆放位置表，进行药品填充，直至药箱药量提示灯变为绿色，即表示药品填充完毕",
      //   },
      // ],
    };
  },
  methods: {
    // playAnimation() {
    //   const eles = document.getElementsByClassName("animation hideElement");
    //   eles[0].classList.add("fade-in");
    //   setTimeout(() => {
    //     eles[1].classList.add("fade-in");
    //     setTimeout(() => {
    //       eles[2].classList.add("fade-in");
    //       setTimeout(() => {
    //         eles[3].classList.add("fade-in");
    //         setTimeout(() => {
    //           eles[4].classList.add("fade-in");
    //           setTimeout(() => {
    //             eles[5].classList.add("fade-in");
    //             setTimeout(() => {
    //               eles[6].classList.add("fade-in");
    //               setTimeout(() => {
    //                 eles[7].classList.add("fade-in");
    //                 setTimeout(() => {
    //                   eles[8].classList.add("fade-in");
    //                   setTimeout(() => {
    //                     eles[9].classList.add("fade-in");
    //                     setTimeout(() => {
    //                       eles[10].classList.add("fade-in");
    //                     }, 500);
    //                   }, 500);
    //                 }, 500);
    //               }, 500);
    //             }, 500);
    //           }, 500);
    //         }, 500);
    //       }, 200);
    //     }, 200);
    //   }, 500);
    // },
    getHeight() {
      const ele = document.getElementsByClassName("animationHeader")[0];
      // console.log(!this.isInViewPortOfOne(ele));
      if (!this.isInViewPortOfOne(ele)) {
        this.playAnimation();
      }
    },
    isInViewPortOfOne(el) {
      // viewPortHeight 兼容所有浏览器写法
      const viewPortHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const offsetTop = el.offsetTop;
      const scrollTop = document.documentElement.scrollTop;
      const top = scrollTop - offsetTop;
      // console.log(viewPortHeight, offsetTop, scrollTop);
      // console.log('top', top)
      // 这里有个+100是为了提前加载+ 100
      return top <= viewPortHeight + 1380;
    },
  },
};
</script>
<style scoped>
.spanThree {
  height: 79px;
  margin-top: -78px;
  margin-left: 20px;
}
.spanFour {
  margin-top: -69px;
  margin-left: 16px;
}
.spanFive {
  height: 79px;
  margin-top: -84px;
  margin-left: 20px;
}
/* @keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

/* .hideElement {
  opacity: 0;
} */

/* .fade-in {
  animation: fade-in;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
} */

.oneRow {
  height: 1655px;
}

/* .twoRow {
  height: 1106px;
  background: #f5f5f7;
}

.threeRow {
  height: 788px;
} */

.controlRow,
.checkRow,
/* .integrateRow, */
.boxRow,
.solveRow {
  width: 76%;
  margin-left: 24%;
}

.checkRow {
  margin-top: 60px;
}

.boxRow {
  margin-top: 3%;
}

.solveRow {
  padding-top: 30px;
}

.controlTitle {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #66bfee;
  letter-spacing: 3px;
}

/* .secondTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  letter-spacing: 2px;
  margin-top: 3%;
} */

.integrate {
  padding-top: 50px;
}
.controlWords {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;

  letter-spacing: 2px;
  text-align: justify;
  padding-top: 40px;
}

.boxATitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 4px;
}

.boxAWords {
  font-size: 16px;
  font-family: Arial;
  font-weight: 400;
  color: #999999;
  letter-spacing: 2px;
  text-align: justify;
}

.boxBTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 3px;
}

.boxBWords {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #999999;
  letter-spacing: 3px;
  margin-top: 4px;
  margin-left: 21px;
  width: 245px;
}

.titleC {
  margin-top: 35px;
}

.titleRow {
  margin-top: 40px;
}

.wordsRow {
  margin-top: 10px;
}

.titleB {
  margin-top: 14px;
}

.box {
  width: 370px;
  height: 280px;
  /* margin-top: 12%; */
  border: 1px solid #999999;
}

.boxImg {
  margin: 36px;
}
.boxCol {
  margin-left: 2%;
}

.medicineCabinet {
  width: 331px;
  height: 350px;
  border: 1px solid #999999;
}

.medicineCabinetImg {
  margin: 6%;
}

.checkTitle {
  margin-top: 13%;
}

.integrateWords {
  margin-top: 3%;
}

.boxA {
  width: 270px;
  height: 160px;
  border: 1px solid #0092de;
  border-radius: 10px;
}

.sign {
  width: 6px;
  height: 16px;
  background: #2ca8e8;
  position: absolute;
  margin-top: 3px;
}

.solveImg {
  position: relative;
}

.record {
  width: 84px;
  height: 73px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  letter-spacing: 3px;
  position: absolute;
  margin-top: -144px;
  margin-left: 56px;
  line-height: 26px;
}

.diamond {
  width: 99px;
  height: 99px;
  background: #98d1f3;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
}

.diamondWords {
  width: 86px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fbfbfb;
  letter-spacing: 4px;
  position: absolute;
}

.secondWords {
  margin-top: -60px;
  margin-left: 16px;
}
.solveTitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 3px;
  padding-left: 54%;
  margin-top: 50px;
}
.diamondTop {
  margin-top: 16%;
}
.diamondBtm {
  margin-top: 82%;
}
.diamondTwo {
  margin-top: -8px;
}

/* .showRow {
  width: 77%;
  height: 29%;
  margin-left: 23%;
  display: flex;
  align-items: center;
} 

.showTitle {
  height: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 3px;
  text-align: center;
}

.showWords {
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #333333;
  letter-spacing: 2px;
  text-align: justify;
}

.use {
  margin-left: 25%;
}
*/
.process {
  width: 979px;
  height: 40px;
  border: 1px solid #0092de;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.processTwo {
  width: 979px;
  height: 70px;
  border: 1px solid #0092de;
  border-radius: 35px;
}

.processWords {
  width: 627px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 3px;
  text-align: center;
}

.triangle {
  width: 979px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
