<template>
  <div @click="showAboutUs" class="headerTitle">
    关于我们
  </div>
</template>
<script>
export default {
  methods: {
    showAboutUs() {
      this.$router.push("/AboutUs")
    }
  }
}
</script>
<style scoped>
.headerTitle {
  width: 84px;
  cursor: pointer;
}
</style>
