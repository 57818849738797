<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">智能语音病历系统</div>
      <div class="sketchText wordsRow">
        <div class="words">
          让医生以口述的方式完成病历和报告的书写，普通话识别率高达97.5%，百万级别的医学专有名词，覆盖医院各科室的病历术语、医学单位、医学符号、医学文献。
        </div>
        <div class="words">
          适用门诊、住院医生，超声、放射等专科医生，医生日常报告内容书写，护士日常护理内容书写，语音移动查房。
        </div>
      </div>

      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>

        <el-col :span="3" class="functionalCol">系统特点</el-col>
      </el-row>

      <el-row class="characteristicRow">
        <el-col
          :span="4"
          :offset="1"
          v-for="(item, index) in characteristicData"
          :key="index"
          class="characteristic"
        >
          <el-row class="titleRow">
            <el-col :span="3">
              <img src="@/assets/photo/Medicalrecord/rectangle.png" />
            </el-col>

            <el-col :span="20" class="titleCol">{{ item.title }}</el-col>
          </el-row>
          <el-row class="bodyRow noneRow">
            <el-col :span="1">
              {{ item.point }}
              <span class="point"></span>
            </el-col>
            <el-col :span="20" :offset="1" class="textOne">{{ item.first }}</el-col>
          </el-row>
          <el-row class="bodyRow" v-if="item.second">
            <el-col :span="1">
              <span class="point"></span>
            </el-col>
            <el-col :span="20" :offset="1">{{ item.second }}</el-col>
          </el-row>
          <el-row class="bodyRow" v-if="item.third">
            <el-col :span="1">
              <span class="point"></span>
            </el-col>
            <el-col :span="20" :offset="1">{{ item.third }}</el-col>
          </el-row>
          <el-row class="bodyRow" v-if="item.fourth">
            <el-col :span="1">
              <span class="point"></span>
            </el-col>
            <el-col :span="20" :offset="1">{{ item.fourth }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div class="functionalArea twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>

        <el-col :span="3" class="functionalCol">系统功能</el-col>
      </el-row>
      <el-row class="imgRow">
        <img src="@/assets/photo/Medicalrecord/table.png" />
      </el-row>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      characteristicData: [
        {
          title: "识别准确率高",
          first: "准确率超过97%",
          second: "内置百万级医学词汇库",
          third: "分科室语言模型训练自主优化学习",
          fourth: "医疗特殊符号,单位转换效果突出",
        },
        {
          title: "适应能力强",
          first: "支持多地带轻微口音的普通话",
          second: "支持设定特点词条的权重",
          third: "独特的离在线混合识别模式",
          fourth: "支持无网络时的命令词识别",
        },
        {
          title: "抗噪性能佳",
          first: "独创降噪及远场识别算法",
          second: "独创语音增强及说话人规整技术",
          third: "有效抑制医疗环境中的噪声干扰",
          fourth: "平均每分钟识别200-300字",
        },
        {
          title: "可高度集成",
          first: "可轻松通过电脑、ipad、移动查房设备进行录入",
          second: "轻客户端,安装便捷,适配共有/私有云后台服务",
          third: "与各业务系统直接数据交互,无二次开发",
        },
        {
          title: "分科室定制化",
          first: "根据医疗专科语料定制语音识别模型",
          second: "对专科特殊单位、符号专门做优化",
        },
        {
          title: "语音结构化模板",
          first: "支持语音调用模板",
          second: "支持语音对模板元素进行填空,超出范围值给与异常提示",
        },
        {
          title: "语音命令",
          first: "支持新增、修改、删除等常规编辑命令",
          second: "支持光标换行、撤销等命令",
        },
        {
          title: "标准化接口",
          first: "与医院专科系统深度结合对接方便快速语音操作,解放双手",
        },
      ],
    };
  },
  computed: {},
};
</script>
<style scoped>
.wordsRow {
  width: 37%;
  margin-left: 32%;
  display: block;
}
.oneRow {
  height: 849px;
} 
.twoRow {
  height: 822px;
  display: block;
}
.imgRow {
  display: flex;
  justify-content: center;
}
.point {
  width: 8px;
  height: 8px;
  background: #0092de;
  border-radius: 50%;
  position: absolute;
  margin-top: 4%;
}
.characteristicRow {
  width: 70%;
  left: 21%;
}
.characteristic {
  height: 255px;
}
.bodyRow {
  line-height: 25px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 1px;
  left: 16%;
  text-align: justify;
}
.titleRow {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 3px;
  height: 42px;
}
.titleCol {
  margin-top: 5%;
  margin-left: 2%;
}
</style>
