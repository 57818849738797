<template>
  <!-- <div class="home">
    <div class="common-layout"> -->
  <el-container>
    <el-header :class="{ shortHeader: !isShow }">
      <Header />
    </el-header>
    <breadcrumb v-if="isShowBreadcrumb" :key="key"/>
    <el-main>
      <router-view :key="key" />
      <back-to-top
        :custom-style="myBackToTopStyle"
        :visibility-height="300"
        :back-position="0"
        transition-name="fade"
      />
    </el-main>
    <!-- <el-footer><Footer/></el-footer> -->
  </el-container>
  <!-- </div>
  </div> -->
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import BackToTop from "@/components/BackToTop";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Header,
    // Index,
    Breadcrumb,
    BackToTop,
    // Footer
  },
  data() {
    return {
      myBackToTopStyle: {
        right: "28px",
        bottom: "194px",
        width: "40px",
        height: "40px",
        "border-radius": "4px",
        "line-height": "45px", // 请保持与高度一致以垂直居中 Please keep consistent with height to center vertically
        background: "#666666", // 按钮的背景颜色 The background color of the button
      },
      isShowBreadcrumb: false,
      isShow: ""
    };
  },
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + new Date()
        : this.$route + new Date();
    },
  },
  watch: {
    $route(to, from) {
      this.showBreadcrumb();
      console.log("查询是否显示面包屑");
    },
  },
  created() {
    this.showBreadcrumb();
  },
  methods: {
    showBreadcrumb() {
      const router = this.$route.path;
      console.log(router);
      console.log(router === "/index");
      if (router === "/index") {
        this.isShowBreadcrumb = false;
      } else {
        this.isShowBreadcrumb = true;
      }
    },
    // showBreadcrumb(status) {
    //   console.log(status);
    //   this.isShow = status
    // },
  },
};
</script>
<style scoped>
.el-container {
  position: relative;
  width: 100%;
  min-width: 1900px;
}

.el-header {
  width: 100%;
  height: 150px;
  padding: 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 111;
}

/* .el-divider--horizontal {
  width: 1140px;
  height: 1px;
  background: #999999;
  margin: 0px 398px;
} */
.el-main {
  width: 100%;
  padding: 0;
}

.shortHeader {
  height: 30%;
}
</style>
