
import request from "@/utils/request";

// export function getrecruit() {
//   return request({
//     url: "/getrecruit",
//     method: "get",
//     params: {}
//   });
// }
export function getrecruit() {
  return request({
    url: "/recruit/getrecruit",
    method: "get",
    params: {}
  });
}
