<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">人工智能语音超声、放射系统</div>
      <el-row class="sketchText wordsRow">
        <el-col :span="4" :offset="2" class="words wordsCol">
          人工智能超声助手系统具备一套健全的超声模板，同院内超声系统、医院信息管理系统（HIS）无缝对接，同时具备超声值语音录入和手工录入两种方式，由AI可信人工智能大脑提供算法引擎，来完成语音识别、语音合成和自然语言理解，通过AI引擎快速的完成报告的录入，系统具备良好的降噪处理，以保证语音录入值的准确和高效。
        </el-col>

        <el-col :span="7" :offset="1">
          <!-- <img src="@/assets/photo/ultrasonic/resource4.png" /> -->
          <el-image :src="resource4Url" :preview-src-list="resource4List" />
        </el-col>
      </el-row>
    </div>
    <el-row class="functionalArea twoRow">
      <el-col :span="7">
        <el-row class="imgRow">
          <!-- <img src="@/assets/photo/ultrasonic/resource1.png" /> -->
          <el-image :src="resource1Url" :preview-src-list="resource1List" />
        </el-row>
        <el-row class="imgRow">
          <!-- <img src="@/assets/photo/ultrasonic/resource3.png" /> -->
          <el-image :src="resource3Url" :preview-src-list="resource3List" />
        </el-row>
      </el-col>

      <el-col :span="7">
        <el-row class="imgRow">
          <el-col :span="1" :offset="2">
            <div class="block"></div>
          </el-col>
          <el-col :span="15" class="rightCol">
            <div class="imgTitle">部分界面展示</div>
            <div class="imgText">
              全国首创超声操作模式，检查和报告操作同一人即可完成，三甲医院至少可节省15位报告录入员。
            </div>
          </el-col>
        </el-row>
        <el-row class="imgRow">
          <!-- <img src="@/assets/photo/ultrasonic/resource2.png" /> -->
          <el-image :src="resource2Url" :preview-src-list="resource2List" />
        </el-row>
      </el-col>
    </el-row>

    <Footer />
  </div>
</template>
<script>
import "@/assets/css/system.css";
import Footer from "@/components/Footer.vue";
import { ref } from "@vue/reactivity";
export default {
  components: {
    Footer,
  },
  setup() {
    //正常的图片
    const resource4Url = ref(require("@/assets/photo/ultrasonic/resource4.png"));
    const resource1Url = ref(require("@/assets/photo/ultrasonic/resource1.png"));
    const resource3Url = ref(require("@/assets/photo/ultrasonic/resource3.png"));
    const resource2Url = ref(require("@/assets/photo/ultrasonic/resource2.png"));
    //点击后的图片
    const resource4List = ref([
      require("@/assets/photo/el-img/Ultrasonic/el-resource4.png"),
    ]);
    const resource1List = ref([
      require("@/assets/photo/el-img/Ultrasonic/el-resource1.png"),
    ]);
    const resource3List = ref([
      require("@/assets/photo/el-img/Ultrasonic/el-resource3.png"),
    ]);
    const resource2List = ref([
      require("@/assets/photo/el-img/Ultrasonic/el-resource2.png"),
    ]);
    return {
      resource4Url,
      resource4List,
      resource1Url,
      resource1List,
      resource2Url,
      resource2List,
      resource3Url,
      resource3List
    };
  },
};
</script>
<style scoped>
.wordsRow {
  height: 315px;
  align-items: center;
}
.wordsCol {
  /* width: 335px; */
  height: 280px;
}
.oneRow {
  height: 510px;
}
.twoRow {
  height: 790px;
}
.imgRow {
  height: 330px;
  display: flex;
  align-items: center;
}
.block {
  width: 10px;
  height: 100px;
  background: #0092de;
}
.imgTitle {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 3px;
  height: 40px;
}
.imgText {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 2px;
  text-align: justify;
}
.rightCol {
  padding-left: 8px;
}
</style>
