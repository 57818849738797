<template>
  <div class="sketch oneRow">
    <div class="sketchTitle" v-if="type == 'Brain'">肿瘤辅助手术规划系统</div>
    <div class="sketchTitle" v-else>安全决策手术系统</div>
    <el-row class="functionalRow">
      <el-col :span="1" :offset="5" class="functionalImg">
        <img src="@/assets/photo/Medicalrecord/semicircle.png" />
      </el-col>
      <el-col class="functionalCol" :span="3">系统特点</el-col>
    </el-row>
    <el-row class="characteristic">
      <el-col :span="3" :offset="6">
        <!-- <img src="@/assets/photo/Operation/machine.png" /> -->
        <el-image :src="machineUrl" :preview-src-list="machineList" />
      </el-col>
      <el-col :span="3">
        <div class="textOne textLeft">
          <div class="textTitle">患者全景医疗信息汇总</div>
          <div class="textBody">质量提升了</div>
        </div>
        <div class="textTwo textLeft">
          <div class="textTitle">手术医师直接操作影像</div>
          <div class="textBody">影像三维重构分屏显示</div>
          <div class="textBody">语音记录转手术病历</div>
        </div>
      </el-col>
      <el-col :span="4" v-if="type == 'Brain'">
        <img src="@/assets/photo/Operation/relationshipB.png" />
      </el-col>
      <el-col :span="4" v-else>
        <img src="@/assets/photo/Operation/relationship.png" />
      </el-col>
      <el-col :span="3">
        <div class="textOne textRight">
          <div class="textTitle">非接触操作保证无菌安全</div>
          <div class="textBody">感染控制提升了</div>
        </div>
        <div class="textTwo textRight" v-if="type == 'Brain'">
          <div class="textTitle">快速病理</div>
          <div class="textBody">5G技术三分钟内出病理评估</div>
        </div>
        <div class="textTwo textRight" v-else>
          <div class="textTitle">多模态智能人机交互</div>
          <div class="textBody">语音识别,手势识别,虚拟屏幕</div>
        </div>
      </el-col>
    </el-row>

    <div class="ellipseRow">
      <three-section
        :section1="part1.section1"
        :section2="part1.section2"
        :section3="part1.section3"
      ></three-section>
      <two-section
        :section1="parta.section1"
        :section2="parta.section2"
      ></two-section>
      <three-section
        :section1="part2.section1"
        :section2="part2.section2"
        :section3="part2.section3"
      ></three-section>
      <two-section
        :section1="partb.section1"
        :section2="partb.section2"
      ></two-section>
      <three-section
        :section1="part3.section1"
        :section2="part3.section2"
        :section3="part3.section3"
      ></three-section>
    </div>

    <el-row>
      <el-col :span="4" :offset="7">
        <div class="browse">
          <div class="browseTitle">浏览：</div>
          <div class="browseBody">遵循DICOM标准;</div>
          <div class="browseBody">
            支持多种格式、影像播放、缩略图、检 查、序列快速导航等功能;
          </div>
        </div>
      </el-col>
      <el-col :span="4" :offset="2">
        <div class="browse">
          <div class="browseTitle">处理：</div>
          <div class="browseBody">
            支持自定义布局显示、触屏调节、跨平台图像标注、图像移动、图像缩放、图像旋转、图像测量，智齿预设、自定义窗宽/窗位值等功能。
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row class="functionalRow">
      <el-col :span="1" :offset="5" class="functionalImg">
        <img src="@/assets/photo/Medicalrecord/semicircle.png" />
      </el-col>
      <el-col class="functionalCol" :span="3">手术核查</el-col>
    </el-row>
    <el-row>
      <el-col :span="7" :offset="6" class="textBody"
        >麻醉诱导前核查，手术开始前、切皮前、出手术室前的多方核对、签字。</el-col
      >
      <el-col :span="3" :offset="1">
        <!-- <img src="@/assets/photo/Operation/check.png" alt="" /> -->
        <el-image :src="checkUrl" :preview-src-list="checkList" />
      </el-col>
    </el-row>
  </div>

  <div class="twoRow">
    <el-row class="functionalRow">
      <el-col :span="1" :offset="5" class="functionalImg">
        <img src="@/assets/photo/Medicalrecord/semicircle.png" />
      </el-col>
      <el-col class="functionalCol" :span="3">术前功能</el-col>
    </el-row>
    <el-row>
      <el-col :span="5" :offset="6">
        <el-row class="preoperativeTitle">患者临床数据全景展示</el-row>
        <el-row class="preoperativebody">
          患者HIS、LIS、PASS、EMP、RIS等系统中各项手术患者的临床数据。建立手术患者虚拟数据调阅中心，便于手术医生随时调阅手术患者资料。
        </el-row>
        <el-row class="preoperativeTitle">多模态交互</el-row>
        <el-row class="preoperativebody">
          考虑手术室的特殊环境，系统可实现多种方式的人机交互，在语音交互、虚拟屏幕交互、动作交互中自由切换。
        </el-row>
      </el-col>
      <el-col :span="6" :offset="1">
        <!-- <img src="@/assets/photo/Operation/view.png" /> -->
        <el-image :src="viewUrl" :preview-src-list="viewList" />
        <div class="operationText browsing">患者基本信息浏览</div>
      </el-col>
    </el-row>

  </div>

  <div class="threeRow">
    <el-row class="functionalRow">
      <el-col :span="1" :offset="5" class="functionalImg">
        <img src="@/assets/photo/Medicalrecord/semicircle.png" />
      </el-col>

      <el-col class="functionalCol" :span="3">术中功能</el-col>
    </el-row>
    <el-row>
      <el-col :span="5" :offset="6" class="CT">
        <!-- <img src="@/assets/photo/Operation/resource1.png" /> -->
        <el-image :src="resource1Url" :preview-src-list="resource1List" />
        <div class="operationText">CT/MRI影像操控</div>
      </el-col>
      <el-col :span="5">
        <!-- <img
            src="@/assets/photo/Operation/resource2.png"
            class="reconstruction"
          /> -->
        <el-image
          :src="resource2Url"
          :preview-src-list="resource2List"
          class="reconstruction"
        />
        <div class="operationText">三维重建</div>
      </el-col>
      <el-col :span="5">
        <!-- <img src="@/assets/photo/Operation/resource3.png" /> -->
        <el-image :src="resource3Url" :preview-src-list="resource3List" />
        <div class="operationText">术中分屏操控</div>
      </el-col>
    </el-row>
    <el-row class="Intraoperative">
      <el-col
        :span="5"
        :offset="3"
        class="intraoperativeCol"
        v-for="item in IntraoperativeData"
        :key="item"
      >
        <el-row>
          <el-col :span="1">
            <span class="block"></span>
          </el-col>
          <el-col :span="22" :offset="1" class="blockTitle">
            {{ item.IntraoperativeTitle }}
          </el-col>
        </el-row>
        <el-row class="blockBody">{{ item.IntraoperativeBody }}</el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import "@/assets/css/system.css";
import ThreeSection from "@/components/StepSections/ThreeSection";
import TwoSection from "@/components/StepSections/TwoSection";
import { ref } from "@vue/reactivity";
export default {
  components: {
    ThreeSection,
    TwoSection,
  },
  setup() {
    //正常的图片
    const viewUrl = ref(require("@/assets/photo/Operation/view.png"));
    const machineUrl = ref(require("@/assets/photo/Operation/machine.png"));
    const checkUrl = ref(require("@/assets/photo/Operation/check.png"));
    const resource1Url = ref(require("@/assets/photo/Operation/resource1.png"));
    const resource2Url = ref(require("@/assets/photo/Operation/resource2.png"));
    const resource3Url = ref(require("@/assets/photo/Operation/resource3.png"));
    //点击后的图片
    const viewList = ref([
      require("@/assets/photo/el-img/Operation/el-view.png"),
    ]);
    const machineList = ref([
      require("@/assets/photo/el-img/Operation/el-machine.png"),
    ]);
    const checkList = ref([
      require("@/assets/photo/el-img/Operation/el-check.png"),
    ]);
    const resource1List = ref([
      require("@/assets/photo/el-img/Operation/el-resource1.png"),
    ]);
    const resource2List = ref([
      require("@/assets/photo/el-img/Operation/el-resource2.png"),
    ]);
    const resource3List = ref([
      require("@/assets/photo/el-img/Operation/el-resource3.png"),
    ]);
    return {
      viewUrl,
      viewList,
      machineUrl,
      machineList,
      checkUrl,
      checkList,
      resource1Url,
      resource1List,
      resource2Url,
      resource2List,
      resource3Url,
      resource3List,
    };
  },
  data() {
    return {
      IntraoperativeData: [
        {
          IntraoperativeTitle: "三维重建",
          IntraoperativeBody:
            "从横断层到多层面乃至立体的改变, 让抽象变得更形象，大大提高诊断的 准确性，缩减了手术的难度和时间",
        },
        {
          IntraoperativeTitle: "AI手术医生助手",
          IntraoperativeBody:
            "AI语音控制，快速调阅数据影像； 记录手术方案变更事件； 提供手术过程质控依据",
        },
        {
          IntraoperativeTitle: "多分屏对比",
          IntraoperativeBody:
            "支持多任务多窗口的分屏操作，主屏幕与分屏幕独立工作，互不干扰",
        },
        {
          IntraoperativeTitle: "患者医疗、数据调阅、术中会诊",
          IntraoperativeBody:
            "HIS、EMR、PASS、LIS、RIS等 医疗信息系统数据自动化集成",
        },
        {
          IntraoperativeTitle: "医疗全数据接入，获取手术细节",
          IntraoperativeBody:
            "可接入术野、全景、腹腔镜及其他视频源，获取手术方方面面的细节；接入医疗全数据链，便于调阅患者各类信息",
        },
        {
          IntraoperativeTitle: "手术数据归档，语音手术病历",
          IntraoperativeBody:
            "提供手术过程记录管理功能无纸化记录，再次浏览便捷高效",
        },
      ],
      part1: {
        section1: {
          id: "01",
          title: "手术安排",
          body: "今日手术排程信息",
        },
        section2: {
          id: "02",
          title: "患者信息",
          body: "患者手术安排详细信息显示",
        },
        section3: {
          id: "03",
          title: "临床数据",
          body:
            "患者所有在院其间治疗主句、" +
            "电子病历、检查报告、检验" +
            "报告、医学影像、体征记录、" +
            "医嘱信息等",
        },
      },
      part2: {
        section1: {
          id: "06",
          title: "挂片对比",
          body: "系统支持多个CT/MRI的挂" + "片对比",
        },
        section2: {
          id: "07",
          title: "图像布局",
          body: "通过指令图像按照图像布局" + "展示布局分为:一,二,四,九",
        },
        section3: {
          id: "08",
          title: "序列布局",
          body: "通过指令图像按照序列布局" + "展示布局分为:一,二,四,九",
        },
      },
      part3: {
        section1: {
          id: "11",
          title: "三维重建",
          body: "支持对三维图像放大,移动," + "旋转等多种操作模式",
        },
        section2: {
          id: "12",
          title: "图像灰度",
          body: "下达指令图像按照指令要求" + "调节图像灰度",
        },
        section3: {
          id: "13",
          title: "图像报告",
          body: "支持多种Modality:CT,MRI, " + "DX,CR,PT,XA,US等",
        },
      },
      parta: {
        section1: {
          id: "04",
          title: "阳性体征",
          body: "在院其间阳性指标数据汇总",
        },
        section2: {
          id: "05",
          title: "语音识别",
          body: "通过语音下达系统指令，完" + "成操作",
        },
      },
      partb: {
        section1: {
          id: "09",
          title: "图像旋转",
          body: "下达指令图像按照指令要求" + "旋转",
        },
        section2: {
          id: "10",
          title: "图像亮度",
          body: "下达指令图像按照指令要求" + "调节亮度",
        },
      },
      type: "",
    };
  },
  created() {
    const all = this.$route.path.split("/");
    this.type = all[1];
  },
};
</script>

<style scoped>
.oneRow {
  height: 1774px;
}
.twoRow {
  height: 471px;
  background: #f5f5f7;
}

.threeRow {
  height: 739px;
}

.characteristic {
  align-items: center;
}

.textOne {
  margin-top: 14%;
}

.textTwo {
  height: 63px;
  margin-top: 68%;
}

.textLeft {
  padding-left: 28%;
}

.textRight {
  padding-left: 4%;
}

.textTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  height: 23px;
}

.textBody {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  align-items: center;
  display: flex;
  letter-spacing: 2px;
}

.preoperativeTitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 8%;
  height: 28px;
  letter-spacing: 2px;
}

.preoperativebody {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: justify;
}

.ellipseRow {
  /* height: 50%; */
  padding-top: 66px;
  padding-bottom: 69px;
}

.browse {
  width: 300px;
  height: 100px;
  background: #0092de;
  border-radius: 11px;
  padding: 8px;
}

.browseTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fbfbfb;
  height: 25px;
  letter-spacing: 2px;
}

.browseBody {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  line-height: 21px;
  text-align: justify;
}

.CT {
  margin-left: 22%;
}

.reconstruction {
  margin-left: -3%;
}

.Intraoperative {
  width: 69%;
  height: 42%;
  margin: 0 249px;
}

.intraoperativeCol {
  padding-top: 3%;
}

.block {
  width: 8px;
  height: 20px;
  background: #0092de;
  display: block;
}

.blockTitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 2px;
  height: 27px;
}

.blockBody {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: justify;
}

.check {
  margin-left: -10%;
}
.browsing {
  padding-left: 5%;
}
</style>