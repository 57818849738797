<template>
  <div class="index-page">
    <div class="banner">
      <bannerSwiper></bannerSwiper>
    </div>
    <div class="product-center">
      <productSwiper></productSwiper>
    </div>
    <div class="news-information">
      <news></news>
    </div>
    <div class="about">
      <about></about>
    </div>
    <div class="footer"><Footer /></div>

    <!-- <back-to-top
        :custom-style="myBackToTopStyle"
        :visibility-height="300"
        :back-position="0"
        transition-name="fade"
      /> -->
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import bannerSwiper from "@/components/Swiper/bannerSwiper.vue";
import productSwiper from "@/components/Swiper/productSwiper.vue";
import news from "@/components/news.vue";
import about from "@/components/about.vue";
export default {
  components: {
    Footer,
    bannerSwiper,
    productSwiper,
    news,
    about,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 600px;
}
.product-center {
  height: 649px;
}
.news-information {
  height: 737px;
  background: #f5f5f7;
}
.about {
  height: 653px;
  padding-top: 59px;
  /*display: flex;
   align-items: center; */
}
.footer {
  height: 274px;
}
</style>
