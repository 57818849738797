<template>
  <div class="system">
    <operation></operation>

    <div class="fourRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5"  class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>

        <el-col class="functionalCol" :span="4">5G远程ROSE病理评估</el-col>
      </el-row>

      <rosepathology></rosepathology>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import rosepathology from "@/components/rosepathology.vue";

import operation from "@/components/operation.vue";

export default {
  components: {
    Footer,
    rosepathology,
    operation,
  },
};
</script>
<style scoped>

.fourRow {
  height: 743px;
  background: #f5f5f7;
}
</style>

