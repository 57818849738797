<template>
  <div class="aboutUsPage system">
    <div class="introduction">
      <div class="sketchTitle">企业简介</div>
      <el-row class="introductionRow">
        <el-col :span="7" :offset="5">
          <!-- <img src="../assets/photo/Aboutus/introduction.png"> -->
          <el-image
            :src="aboutUrl"
            :preview-src-list="aboutList"
          />
        </el-col>
        <el-col :span="6" :offset="1" class="contentCol">
          广州信瑞医疗技术有限公司是专注于开发智慧医疗和医疗大数据应用领域，集研发、生产、销售及服务为一体的高科技企业，广州市创业领军团队项目，核心团队由美籍华裔科学家、亚利桑那大学兼职教授、复旦大学兼职教授；中科院国家特津专家和清华教授组成。公司打造以区域龙头医院为中心、连接社区、赋能院内资源、辐射医联体，实现安全决策医疗。
        </el-col>
      </el-row>
    </div>

    <div class="culture">
      <div class="sketchTitle">企业文化</div>
      <el-row class="introductionRow cultureRow">
        <el-col :span="7" class="contentCol">
          公司拥有一支年轻团队，注重年轻化、创造力以及积极向上的工作氛围。团队成员相处融洽、配合默契、互帮互助。公司以及所有员工都积极投身于我国的医疗技术开发事业中，都想创造出更多有利于临床治疗或康复治疗的产品。</el-col
        >
      </el-row>
    </div>

    <div class="rowFour">
      <div class="honor">
        <div class="sketchTitle">荣誉资质</div>
        <honorSwiper />
      </div>
      <div class="cooperation">
        <div class="sketchTitle">合作关系</div>
        <cooperationSwiper />
      </div>
    </div>

    <div class="contact">
      <div class="sketchTitle">联系我们</div>
      <el-row class="introductionRow contactUS">
        <el-col :span="8" :offset="4">
          <!--          <img src="../../src/assets/photo/Aboutus/contact.png">-->
          <div id="container"></div>
        </el-col>

        <el-col :span="7" :offset="1" class="contentCol">
          <el-row class="contactRow">
            <el-col :span="2" class="imgCol">
              <img src="../assets/photo/Aboutus/phone.png" />
            </el-col>
            <el-col :span="4" :offset="2" class="contactTitle">电话：</el-col>
            <el-col :span="14" class="contactText">(020)28211365</el-col>
          </el-row>
          <el-row class="contactRow">
            <el-col :span="2" class="imgCol">
              <img src="../assets/photo/Aboutus/email.png" class="imgEmail" />
            </el-col>
            <el-col :span="4" :offset="2" class="contactTitle">邮箱：</el-col>
            <el-col :span="14" class="contactText emailText"
              >supports@thearay.com</el-col
            >
          </el-row>
          <el-row class="contactRow">
            <el-col :span="2" class="imgCol">
              <img src="../assets/photo/Aboutus/adress.png" />
            </el-col>
            <el-col :span="4" :offset="2" class="contactTitle">地址：</el-col>
            <el-col :span="14" class="contactText">
              广东省广州市黄埔区广州开发区科学大道121号科城大厦
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import honorSwiper from "@/components/Swiper/honorSwiper.vue";
import cooperationSwiper from "@/components/Swiper/cooperationSwiper.vue";
import AMap from "AMap"; // 引入高德地图AMap
import { ref } from "@vue/reactivity";
export default {
  components: {
    Footer,
    honorSwiper,
    cooperationSwiper,
  },
  setup() {
    //正常的图片
    const aboutUrl = ref(require("@/assets/photo/Aboutus/introduction.png"));
    //点击后的图片
    const aboutList = ref([
      require("@/assets/photo/el-img/el-introduction.jpg"),
    ]);
    return {
      aboutUrl,
      aboutList,
    };
  },
  data() {
    return {
      myBackToTopStyle: {
        right: "50px",
        bottom: "50px",
        width: "40px",
        height: "40px",
        "border-radius": "4px",
        "line-height": "45px", // 请保持与高度一致以垂直居中 Please keep consistent with height to center vertically
        background: "#666666", // 按钮的背景颜色 The background color of the button
      },
    };
  },
  mounted() {
    const map = new AMap.Map("container", {
      zoom: 17, // 级别
      center: [113.44, 23.167], // 中心点坐标
    });
    // 创建一个 icon
    const endIcon = new AMap.Icon({
      size: new AMap.Size(34, 46),
      image: require("@/assets/photo/Aboutus/mapdot.png"),
      imageSize: new AMap.Size(24, 31),
      // imageOffset: new AMap.Pixel(-95, -3)
    });
    const marker = new AMap.Marker({
      // position: [113.44, 23.167],
      position: [113.44, 23.1663],
      offset: new AMap.Pixel(-14, -16),
      icon: endIcon, // 添加 Icon 图标 URL
      title: "信瑞医疗",
    });

    map.add(marker);
  },
};
</script>
<style scoped>
.el-divider--horizontal {
  width: 1140px;
  height: 1px;
  background: #999999;
  margin: 0px 398px 0;
  /* margin: 10px 398px 0; */
}

.introduction {
  height: 549px;
  background: #ffffff;
}

.introductionRow {
  /* height: 390px; */
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.cultureRow {
  justify-content: center;
}
.contactUS {
  height: 542px;
}

#container {
  width: 620px;
  height: 500px;
}

.contentCol {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
  line-height: 23px;
  text-align: justify;
}

.culture {
  height: 292px;
  background: #f5f5f7;
}

.rowFour {
  height: 696px;
  background: #ffffff;
}

.contact {
  height: 700px;
  background: #f5f5f7;
}

.contactRow {
  height: 110px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  line-height: 35px;
}

.imgCol {
  display: flex;
  justify-content: center;
}
.imgEmail {
  margin-top: 5px;
}

.contactText {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0092de;
  letter-spacing: 6px;
  /* line-height: 35px; */
}
.emailText {
  letter-spacing: 4px;
}

.contactTitle {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/* .  {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  height: 80px;
  line-height: 80px;
  letter-spacing: 7px;
} */

/* .honor {
  display: flex;
  justify-content: center;
} */
.footer {
  height: 274px;
}
</style>
