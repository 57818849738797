<template>
  <div class="system">
    <div class="">
      <div class="sketchTitle">产品中心</div>
    </div>
    <div v-if="showPageOne" class="pageOne">
      <el-row class="line-container">
        <el-col :span="7" :offset="5" class="leftPart">
          <simple-product
            :system-info="pageOne.system1"
            @click="clickProduct"
          />
        </el-col>
        <el-col class="rightPart" :span="7" :offset="1">
          <simple-product
            :system-info="pageOne.system2"
            @click="clickConsultation"
          />
        </el-col>
      </el-row>
      <el-row class="line-container">
        <el-col :span="7" :offset="5" class="leftPart">
          <simple-product
            :system-info="pageOne.system3"
            @click="clickUltrasonic"
          />
        </el-col>
        <el-col class="rightPart" :span="7" :offset="1">
          <simple-product
            :system-info="pageOne.system4"
            @click="clickMedicalRecord"
            class="product4"
          />
        </el-col>
      </el-row>
      <el-row class="line-container">
        <el-col :span="7" :offset="5" class="leftPart">
          <simple-product
            :systemInfo="pageOne.system5"
            @click="clickOperation"
            class="product5"
          ></simple-product>
        </el-col>
        <el-col class="rightPart" :span="7" :offset="1">
          <simple-product
            :system-info="pageOne.system6"
            @click="clickNursing"
          />
        </el-col>
      </el-row>
    </div>
    <div v-if="showPageTwo" class="pageTwo">
      <el-row class="line-container">
        <el-col :span="7" :offset="5" class="leftPart">
          <simple-product
            :system-info="pageTwo.system1"
            @click="clickFollowup"
          />
        </el-col>
        <el-col class="rightPart" :span="7" :offset="1">
          <simple-product
            :system-info="pageTwo.system2"
            @click="clickFirstaid"
          />
        </el-col>
      </el-row>
      <el-row class="line-container">
        <el-col :span="7" :offset="5" class="leftPart">
          <simple-product
            :system-info="pageTwo.system3"
            @click="clickcCoordination"
          />
        </el-col>
        <el-col class="rightPart" :span="7" :offset="1">
          <simple-product
            :system-info="pageTwo.system4"
            @click="clickPathology"
            class="product10"
          />
        </el-col>
      </el-row>
      <el-row class="line-container">
        <el-col :span="7" :offset="5" class="leftPart">
          <simple-product :system-info="pageTwo.system5" @click="clickRose" />
          <!--          <two-image :systemInfo="pageOne.system5"></two-image>-->
        </el-col>
        <el-col class="rightPart" :span="7" :offset="1">
          <simple-product :system-info="pageTwo.system6" @click="clickBrain" class="product12"/>
        </el-col>
      </el-row>
    </div>
    <div v-if="showPageThree" class="pageThree">
      <el-row class="line-container">
        <el-col :span="7" :offset="5" class="leftPart">
          <simple-product
            :system-info="pageThree.system1"
            @click="clickNarcoticsystem"
            class="product13"
          />
        </el-col>
        <el-col class="rightPart" :span="7" :offset="1">
          <simple-product
            :system-info="pageThree.system2"
            @click="clickWisdom"
          />
        </el-col>
      </el-row>
    </div>

    <div class="paginationRow">
      <el-pagination
        :default-current-page="2"
        :current-page="currentPageNum"
        background
        layout="prev, pager, next"
        :total="30"
        @current-change="changePager"
      />
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

import SimpleProduct from "@/components/SimpleProduct/SimpleProduct";

export default {
  name: "Product",
  components: {
    SimpleProduct,
    Footer,
  },
  data() {
    return {
      currentPageNum: 1,
      whichPage: "1",
      pageOne: {
        system1: {
          url: require("@/assets/photo/ProductsIntro/Guidance.png"),
          name: "智能导诊系统",
          description:
            "利用知识库于自主学习，基于诊断学知识图谱，智能的为病人推荐科室和医生...",
        },
        system2: {
          url: require("@/assets/photo/ProductsIntro/Consultation.png"),
          name: "智能预问诊系统",
          description:
            "通过多轮对话模拟医生与患者交流，采集病人病情信息发送给医生...",
        },
        system3: {
          url: require("@/assets/photo/ProductsIntro/Ultrasonic.png"),
          name: "人工智能超声、放射系统",
          description:
            "全国首创超声操作模式，检查和报告操作同一人即可完成，三甲医院至少可节省15位报告录入员...",
        },
        system4: {
          url: require("@/assets/photo/ProductsIntro/Medicalrecord.png"),
          name: "智能语音病历系统",
          description:
            "利用知识库于自主学习，基于诊断学知识图谱，智能的为病人推荐科室和医生...",
        },
        system5: {
          url: require("@/assets/photo/ProductsIntro/Operation.png"),
          name: "安全决策手术系统",
          description:
            "全球首创将多模态融合（语音、虚拟触摸、3D手势）结合手术核查，并将智能交互技术应用于实际产品...",
        },
        system6: {
          url: require("@/assets/photo/ProductsIntro/Nursing.png"),
          name: "智能护理交互室系统",
          description:
            "升级为第三代护士站交互系统，关注内容集中展示，语音语义交互全面应用...",
        },
      },
      pageTwo: {
        system1: {
          url: require("@/assets/photo/ProductsIntro/Followup.png"),
          name: "AI随访系统",
          description:
            "通过人工智能构建患者全病程管理体系，改变传统随访方式，减少工作人员工作量，并对患者有针对性的关注...",
        },
        system2: {
          url: require("@/assets/photo/ProductsIntro/Firstaid.png"),
          name: "5G急救系统",
          description: "呼叫即入院，患者信息实时传输，及时施救和入院准备...",
        },
        system3: {
          url: require("@/assets/photo/ProductsIntro/5gMultiHospital.png"),
          name: "5G多院区急诊移动协同",
          description: "无需等待扫描，病理科远程低延时诊断...",
        },
        system4: {
          url: require("@/assets/photo/ProductsIntro/5gRemoteDio.png"),
          name: "5G远程冰冻病理诊断",
          description: "无需等待扫描，病理科远程低延时诊断...",
        },
        system5: {
          url: require("@/assets/photo/ProductsIntro/5gRose.png"),
          name: "5G远程ROSE病理评估",
          description: "病理医生远程就可以做手术室边病理诊断...",
        },
        system6: {
          url: require("@/assets/photo/ProductsIntro/Brain.png"),
          name: "肿瘤辅助手术规划系统",
          description:
            "手术安全决策系统配合上5G远程ROSE评估系统来辅助肿瘤手术...",
        },
      },
      pageThree: {
        system1: {
          url: require("@/assets/photo/ProductsIntro/Narcoticsystem.png"),
          name: "智能药箱药柜系统",
          description:
            "麻精药、耗材全程跟踪存储管理、手术台清台结、双核销毁...",
        },
        system2: {
          url: require("@/assets/photo/ProductsIntro/wisdom.png"),
          name: "智慧病理",
          description:
            "病理切片扫描仪；5G远程诊断显微镜；远程诊断平台；病理智能标注系统；病理智能诊断；",
        },
      },
    };
  },
  computed: {
    showPageOne() {
      console.log(this.whichPage);
      return this.whichPage === 1 || this.whichPage === "1";
    },
    showPageTwo() {
      console.log(this.whichPage);
      return this.whichPage === 2 || this.whichPage === "2";
    },
    showPageThree() {
      console.log(this.whichPage);
      return this.whichPage === 3 || this.whichPage === "3";
    },
  },

  created() {
    this.whichPage = this.$route.query.pid;
    // this.currentPageNum = this.whichPage
    if (!this.whichPage) {
      this.whichPage = 1;
    }
    this.currentPageNum = parseInt(this.whichPage);
    // debugger
    console.log(this.currentPageNum);
  },
  methods: {
    changePager(val) {
      console.log(val);

      // debugger
      this.$router.push({
        path: "/products",
        query: {
          pid: val,
        },
      });
    },
    clickProduct() {
      this.hideProductOptions();
      this.$router.push("/guidance");
    },
    clickConsultation() {
      this.hideProductOptions();
      this.$router.push("/consultation");
    },
    clickUltrasonic() {
      this.hideProductOptions();
      this.$router.push("/ultrasonic");
    },
    clickMedicalRecord() {
      this.hideProductOptions();
      this.$router.push("/medicalrecord");
    },
    clickOperation() {
      this.hideProductOptions();
      this.$router.push("/operation");
    },
    clickNursing() {
      this.hideProductOptions();
      this.$router.push("/nursing");
    },
    clickFollowup() {
      this.hideProductOptions();
      this.$router.push("/followup");
    },
    clickFirstaid() {
      this.hideProductOptions();
      this.$router.push("/firstaid");
    },
    clickcCoordination() {
      this.hideProductOptions();
      this.$router.push("/coordination");
    },
    clickPathology() {
      this.hideProductOptions();
      this.$router.push("/pathology");
    },
    clickRose() {
      this.hideProductOptions();
      this.$router.push("/rose");
    },
    clickBrain() {
      this.hideProductOptions();
      this.$router.push("/brain");
    },
    clickNarcoticsystem() {
      this.hideProductOptions();
      this.$router.push("/narcoticsystem");
    },
    clickWisdom() {
      this.hideProductOptions();
      this.$router.push("/wisdom");
    },
    hideProductOptions() {
      this.isHover = false;
    },
  },
};
</script>

<style scoped>
.product4 >>> .system-name,
.product5 >>> .system-name,
.product13 >>> .system-name {
  width: 170px;
}
.product10 >>> .system-name {
  width: 163px;
}
.product12 >>> .system-name {
  width: 160px;
}

.pageOne,
.pageTwo,
.pageThree {
  padding-bottom: 60px;
}

.paginationRow {
  padding-bottom: 100px;
}

.line-container {
  padding-top: 41px;
}

.leftPart {
  padding-left: 3px;
}

.rightPart {
  margin-left: 35px;
}

.paginationRow {
  display: flex;
  justify-content: center;
}
</style>
