<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">智慧病理</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系列病理切片扫描仪</el-col>
      </el-row>
      <el-row class="imgRow">
        <el-col :span="4">
          <el-image :src="scannerUrl" :preview-src-list="scannerList" />
        </el-col>
        <el-col :span="7" :push="1">
          <img src="@/assets/photo/wisdom/row1.png" alt="" />
        </el-col>
      </el-row>
    </div>

    <div class="twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="4">5G远程操控诊断显微镜</el-col>
      </el-row>
      <el-row class="microscopeRow">
        <el-col :span="3">
          <el-image :src="row2Url" :preview-src-list="row2List" />
        </el-col>
        <el-col :span="6" class="microscopeText">
          <span class="textTwo"
            >病理医生在自己办公室就能操控远程显微镜做诊断，让远程术中冰冻、ROSE病理成为可能。</span
          >
        </el-col>
      </el-row>
      <el-row class="introdustion">
        <el-col :span="8" :offset="2">
          <el-row class="introductionText">
            <el-col :span="1">1）</el-col>
            <el-col :span="22" :offset="1"
              >通过5G网络，病理医生在移动端或办公室做远程病理评估</el-col
            >
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">2）</el-col>
            <el-col :span="22" :offset="1">
              配置物镜4X/10X/20X/40X（可选60X）</el-col
            >
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">3）</el-col>
            <el-col :span="22" :offset="1">
              活检无需送病理科，快速染色台现场做切片（不超过1分钟）</el-col
            >
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">4）</el-col>
            <el-col :span="22" :offset="1">
              保留本地诊断操作，并可输出到显示器</el-col
            >
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">5）</el-col>
            <el-col :span="22" :offset="1">5G/4G宽带任意切换</el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">6）</el-col>
            <el-col :span="22" :offset="1">
              神经脑外科、呼吸科、妇科、内镜室、B超室、肿瘤科等手术室或门诊做病理诊断、早癌筛查</el-col
            >
          </el-row>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-row class="center section">
            <el-image :src="sectionUrl" :preview-src-list="sectionList" />
          </el-row>
          <el-row class="center">
            <div class="rosepathologyText">5G远程切片放大系统</div>
          </el-row>
          <el-row class="center rowTwo">
            <el-image :src="dyeUrl" :preview-src-list="dyeList" />
          </el-row>
          <el-row class="center">
            <div class="rosepathologyText">快速染色台</div>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <div class="threeRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">远程诊断平台</el-col>
      </el-row>
      <el-row class="diagnosisRow">
        <el-col :span="7" :pull="1"
          ><el-image
            :src="row3Url"
            :preview-src-list="row3List"
            class="row3Img"
        /></el-col>
        <el-col :span="5"
          ><el-image :src="diagnosisUrl" :preview-src-list="diagnosisList"
        /></el-col>
      </el-row>
      <el-row class="diagnosisRow">
        <el-col :span="2">
          <div class="diagnosisBox">
            <div>
              <div>1）病例信息管理</div>
              <div>2）病例申请</div>
              <div>3）我的病例管理</div>
              <div>4）快速共享病例</div>
            </div>
          </div>
          <div class="titleThree">申请端</div>
        </el-col>
        <el-col :span="3" :offset="2">
          <div class="diagnosisBox diagnosisCenter">
            <div>
              <div>1）开展疑难病理远程会诊</div>
              <div>2）术中冰冻同步会诊</div>
              <div>3）国际病理远程会诊</div>
              <div>4）快速共享病例</div>
            </div>
          </div>
          <div class="titleThree titleCenter">专家端</div>
        </el-col>
        <el-col :span="2" :offset="2">
          <div class="diagnosisBox">
            <div>
              <div>1）权限管理</div>
              <div>2）系统管理</div>
              <div>3）统计分析</div>
              <div>4）会诊管理</div>
            </div>
          </div>
          <div class="titleThree">管理端</div>
        </el-col>
      </el-row>
    </div>

    <div class="fourRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">病理智能标注系统</el-col>
      </el-row>
      <el-row class="taggingRow">
        <el-col :span="7" :offset="1">
          <el-image :src="row4Url" :preview-src-list="row4List" />
        </el-col>
        <el-col :span="7">
          <el-image :src="taggingUrl" :preview-src-list="taggingList"
        /></el-col>
      </el-row>
      <el-row class="taggingRow tagging">
        <div class="taggingOne taggingText">
          病理智能标注系统切片存储列表，搜索和精确查询
        </div>
        <div class="taggingTwo taggingText artificial">人工标注</div>
      </el-row>

      <el-row class="taggingRow">
        <div class="taggingTwo taggingText">快速标注</div>
        <div class="taggingTwo taggingText">智能标注</div>
        <div class="taggingThree taggingText">快捷的标注数据下载处理</div>
      </el-row>
    </div>

    <div class="fiveRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">病理智能诊断</el-col>
      </el-row>
      <el-row class="textRow">
        <span class="bodyFive">
          <span class="titleFive">病变智能诊断：</span>
          以解决医生的需求为终一目标。胃活检慢性炎症、活动性、萎缩性、肠上皮化生等精确度性能指标：Acc/fwIou/Acc_Class
          均超过95%。</span
        >
      </el-row>

      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="4"
          >院际院内多科会诊管理系统</el-col
        >
      </el-row>
      <el-row class="textRow">
        <span class="bodyFive"
          >多维度临床信息和数字切片融合的会诊管理系统，提供了科内会诊，院内、院际多科会诊，教学培训，读片会，智能标注，智能诊断及开展病理项目研究的集成环境。</span
        >
      </el-row>

      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">病理信息系统</el-col>
      </el-row>
      <el-row class="workstationImg">
        <el-col :span="6" :offset="1">
          <el-image :src="takeUrl" :preview-src-list="takeList" />
        </el-col>
        <el-col :span="6" :offset="1">
          <el-image :src="makeUrl" :preview-src-list="makeList" />
        </el-col>
      </el-row>
      <el-row class="textRow">
        <el-col :span="1"><span class="blueBlock"></span></el-col>
        <el-col :span="2">
          <span class="titleFive">取材工作站：</span>
          <span class="bodyFive">自动识别标本体积、快速录入大体所见描述</span>
        </el-col>
        <el-col :span="1" :offset="4"><span class="blueBlock"></span></el-col>
        <el-col :span="2">
          <span class="titleFive">制片工作站：</span>
          <span class="bodyFive">脱水-包埋-切片-染色-封片全流程跟踪</span>
        </el-col>
      </el-row>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { ref } from "@vue/reactivity";
export default {
  components: {
    Footer,
  },
  setup() {
    //正常的图片
    const scannerUrl = ref(require("@/assets/photo/wisdom/scanner.png"));
    const row2Url = ref(require("@/assets/photo/wisdom/row2.png"));
    const row3Url = ref(require("@/assets/photo/wisdom/row3.png"));
    const row4Url = ref(require("@/assets/photo/wisdom/row4.png"));
    const sectionUrl = ref(require("@/assets/photo/wisdom/section.png"));
    const dyeUrl = ref(require("@/assets/photo/wisdom/dye.png"));
    const diagnosisUrl = ref(require("@/assets/photo/wisdom/diagnosis.png"));
    const takeUrl = ref(require("@/assets/photo/wisdom/take.png"));
    const makeUrl = ref(require("@/assets/photo/wisdom/make.png"));
    const taggingUrl = ref(require("@/assets/photo/wisdom/tagging.png"));
    //点击后的图片
    const scannerList = ref([
      require("@/assets/photo/el-img/Wisdom/el-scanner.png"),
    ]);
    const row2List = ref([require("@/assets/photo/el-img/Wisdom/el-row2.png")]);
    const row3List = ref([require("@/assets/photo/el-img/Wisdom/el-row3.png")]);
    const row4List = ref([require("@/assets/photo/el-img/Wisdom/el-row4.png")]);
    const sectionList = ref([
      require("@/assets/photo/el-img/Wisdom/el-section.png"),
    ]);
    const dyeList = ref([require("@/assets/photo/el-img/Wisdom/el-dye.png")]);
    const diagnosisList = ref([
      require("@/assets/photo/el-img/Wisdom/el-diagnosis.png"),
    ]);
    const takeList = ref([require("@/assets/photo/el-img/Wisdom/el-take.png")]);
    const makeList = ref([require("@/assets/photo/el-img/Wisdom/el-make.png")]);
    const taggingList = ref([
      require("@/assets/photo/el-img/Wisdom/el-tagging.png"),
    ]);
    return {
      row2Url,
      row2List,
      row3Url,
      row3List,
      row4Url,
      row4List,
      scannerUrl,
      scannerList,
      sectionUrl,
      sectionList,
      dyeUrl,
      dyeList,
      diagnosisUrl,
      diagnosisList,
      takeUrl,
      takeList,
      makeUrl,
      makeList,
      taggingUrl,
      taggingList,
    };
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.tagging {
  margin: 40px 0 20px 0;
}
.row3Img {
  margin-right: 85px;
}
.introdustion {
  width: 70%;
  margin-left: 18%;
  padding: 42px 0 60px 0;
}
.rowTwo {
  margin-top: 46px;
}
.rosepathologyText {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #666666;

  letter-spacing: 2px;
  margin-top: 20px;
}
.center {
  justify-content: center;
}
.section {
  margin-top: 26px;
}
.introductionText {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
  margin-top: 10px;
  text-align: justify;
}
.blueBlock {
  width: 6px;
  height: 17px;
  background: #0092de;
  display: block;
  margin-left: 58px;
  margin-top: 2px;
}
.artificial {
  margin-right: 0 !important;
  margin-left: 48px;
}
.taggingText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #221815;
  line-height: 40px;
  text-align: center;
}
.taggingThree {
  width: 250px;
  height: 40px;
  border: 1px solid #3699ff;
  border-radius: 20px;
  /* margin-top: 20px; */
}
.taggingTwo {
  width: 100px;
  height: 40px;
  border: 1px solid #3699ff;
  border-radius: 20px;
  /* margin-top: 20px; */
  margin-right: 53px;
}
.taggingOne {
  width: 410px;
  height: 40px;
  border: 1px solid #3699ff;
  border-radius: 20px;
  /* margin-top: 65px; */
}
.diagnosisRow {
  margin-top: 40px;
}

.titleThree {
  width: 140px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #221815;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
}
.diagnosisCenter,
.titleCenter {
  width: 223px !important;
}
.diagnosisBox {
  width: 140px;
  height: 140px;
  border: 1px solid #98d1f3;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #221815;
  line-height: 24px;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.microscopeText {
  display: flex;
  align-items: center;
}
.workstationImg {
  padding-bottom: 29px;
}
.textTwo {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #221815;
  line-height: 24px;
  letter-spacing: 2px;
}
.textRow,
.microscopeRow,
.diagnosisRow,
.taggingRow,
.workstationImg {
  justify-content: center;
}
.bodyFive {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #221815;
  width: 632px;
  letter-spacing: 2px;
  line-height: 20px;
}
.titleFive {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3699ff;
  letter-spacing: 2px;
}
.imgRow {
  justify-content: center;
  align-items: center;
}
.oneRow {
  height: 716px;
}
.twoRow {
  height: 823px;
  background: #f5f5f7;
}
.threeRow {
  height: 689px;
}
.fourRow {
  height: 614px;
  background: #f5f5f7;
}
.fiveRow {
  height: 876px;
}
</style>
