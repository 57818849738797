<template>
  <div class="newsPage pageHover">
    <div class="newsInformation">新闻动态</div>
    <el-row class="details" v-for="(item, index) in newsLists" :key="item.id">
      <el-col :span="5" :offset="5" @click="showDetail(item.id)">
        <!-- <img src="../assets/photo/news/rocket.png" class="newsImg" /> -->
        <img v-if="item.images" :src="item.images" class="newsImg" />
        <img v-else src="../assets/photo/news/rocket.png" class="newsImg" />
      </el-col>
      <el-col :span="8" :offset="1" @mouseleave="leaveEle(index)" @mouseover="enterEle(index)">
        <el-row>
          <el-col
            :span="18"
            @click="showDetail(item.id)"
            class="newsTitle oneRow "
          >
            {{ item.newsTitle }}
          </el-col>
          <el-col
            :span="5" :offset="1" class="newsTime oneRow"
          >{{ item.newsTime }}
          </el-col>
        </el-row>
        <el-row class="newsContent">{{ item.newsShortBody }}</el-row>
        <el-button @click="showDetail(item.id)" class="btn">查看更多</el-button>
      </el-col>
    </el-row>

    <div class="paginationRow">
      <el-pagination
        background @current-change="getCurrent" layout="prev, pager, next" :page-size="form.pageSize"
        :total="totalNums">
      </el-pagination>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/pagination.css";
import { getAllNews } from "@/api/news.js";
// import 'animate.css';

export default {
  components: {
    Footer,
  },
  data() {
    return {
      isHover: false,
      newsLists: [],
      totalNums: 0,
      current: 0,
      form: {
        page: 1,
        pageSize: 4
      }
    };
  },
  mounted() {
    this.getNewsLists();
  },
  methods: {
    getCurrent(val) {
      console.log(val);
      this.form.page = val;
      this.getNewsLists()
    },
    enterEle(index) {
      // console.log(index);
      console.log("in");
      const ele = document.getElementsByClassName("details");
      const eleColor = document.getElementsByClassName("newsTitle");
      for (const i of ele) {
        // console.log(i);
        i.classList.remove("animated-moveRight");
      }
      ele[index].classList.add("animated-moveRight");
      eleColor[index].classList.add("headerHoverColor");
    },
    leaveEle(index) {
      console.log("out");
      const ele = document.getElementsByClassName("details");
      const eleColor = document.getElementsByClassName("newsTitle");
      ele[index].classList.remove("animated-moveRight");
      eleColor[index].classList.remove("headerHoverColor");
    },
    showDetail(id) {
      this.$router.push({
        path: "/Newsdetail",
        query: {
          id: id,
        },
      });
    },
    getNewsLists() {
      getAllNews(this.form).then((res) => {
        console.log(res);
        this.newsLists = res.data;

        this.totalNums = res.totalNums;
      });
    },
  },
};
</script>
<style scoped>
/* .el-divider--horizontal {
  width: 1140px;
  height: 1px;
  background: #999999;
  margin: 10px 398px 0;
} */

.headerHoverColor {
  color: #0092de !important;

}

.btn:hover {
  color: #0092de;
  background: #fbfbfb;
  border: 1px solid #0092de;
}

.newsInformation {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  /* height: 80px;
  line-height: 80px; */
  letter-spacing: 7px;
  padding-top: 59px;
}

.animated-moveRight {
  animation-duration: 500ms;
  animation-name: slideright;
  animation-fill-mode: forwards;
}

@keyframes slideright {
  from {
    margin-left: 0%;
    width: 100%;
  }

  to {
    margin-left: 1%;
    width: 100%;
  }
}

.newsImg {
  width: 400px;
  height: 280px;
  cursor: pointer;
}

.newsTitle {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
  cursor: pointer;
}

.newsTime {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  letter-spacing: 2px;
  text-align: right;
}

.oneRow {
  /* height: 70px; */
  line-height: 30px;
  padding-bottom: 39px;
}

.newsContent {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 3px;
  /* height: 80px; */
  line-height: 20px;
  text-align: justify;
}

.details {
  padding: 30px 0;
  display: flex;
  align-items: center;
}

.details:nth-child(odd) {
  background: #f5f5f7;
  width: 100%;
}

.details:nth-child(even) {
  background: #ffffff;
  width: 100%;
}

.el-button {
  width: 100px;
  height: 50px;
  background: #0092de;
  border-radius: 25px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  margin-top: 29px;
}

.paginationRow {
  display: flex;
  justify-content: center;
}

.el-pagination {
  padding: 40px 0px 100px;
}
</style>
