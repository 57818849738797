<template>
  <!-- <div class="headerTitle">
    <div @click="clickIndex" class="headerWords"> 首页 </div>
  </div> -->
  <div class="headerTitle" @click="clickIndex">首页</div>
</template>
<script>
export default {
  methods: {
    clickIndex() {
      this.$router.push("/index");
    },
  },
};
</script>
<style scoped>
.headerTitle {
  width: 44px;
  cursor: pointer;
}
/* .headerWords {
  width: 42px;
  height: 90px;
}
.headerWords:hover {
  border-bottom: 8px solid #0092de;
} */
</style>
