<template>
  <div class="footer-page">
    <el-row class="menuRow">
      <el-col :span="1" class="menuCol"
      >
        <indexComponent class="handleWords"></indexComponent
        >
      </el-col>

      <el-col :span="1" :offset="2" class="menuCol"
      >
        <productComponent class="handleWords"></productComponent
        >
      </el-col>
      <el-col :span="1" :offset="2" class="menuCol"
      >
        <newsComponent class="handleWords"></newsComponent
        >
      </el-col>
      <el-col :span="1" :offset="2" class="menuCol"
      >
        <recruitComponent class="handleWords"></recruitComponent
        >
      </el-col>
      <el-col :span="1" :offset="2" class="menuCol"
      >
        <aboutComponent class="handleWords"></aboutComponent
        >
      </el-col>
    </el-row>

    <el-row class="informationRow">
      <el-col :span="1" class="iconPosition">
        <img src="./../assets/photo/footer/adress.png" />
      </el-col>
      <el-col :span="7">
        地址： 广东省广州市黄埔区广州开发区科学大道121号科城大厦
      </el-col>
    </el-row>
    <el-row class="informationRow">
      <el-col :span="1" class="iconPosition">
        <img src="./../assets/photo/footer/phone.png" />
      </el-col>
      <el-col :span="7"> 电话： (020) 28211365</el-col>
    </el-row>
    <el-row class="informationRow">
      <el-col :span="1" class="iconPosition">
        <img src="./../assets/photo/footer/email.png" />
      </el-col>
      <el-col :span="7"> 邮箱： supports@thearay.com</el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row class="informationRow copyRight">
      <el-col :span="9" class="copyrightCol">
        Copyright @ 2021 广州信瑞医疗技术有限公司 版权所有
        <a href="https://beian.miit.gov.cn/" target="_blank">
          粤ICP备20012759号-<span>{{ rightNumber }}</span>
        </a>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import indexComponent from "@/components/PageJump/indexComponent.vue";
import aboutComponent from "@/components/PageJump/aboutComponent.vue";
import productComponent from "@/components/PageJump/productComponent.vue";
import newsComponent from "@/components/PageJump/newsComponent.vue";
import recruitComponent from "@/components/PageJump/recruitComponent.vue";

export default {
  name: "Footer",
  components: {
    indexComponent,
    aboutComponent,
    productComponent,
    newsComponent,
    recruitComponent,
  },
  computed: {
    rightNumber() {
      const href = location.hostname;
      const sep = href.split(".");
      const suffix = sep[sep.length - 1];
      console.log(suffix);
      // return href;
      switch (suffix) {
        case "net":
          return "3";
        case "cn":
          return "2";
        case "com":
          return "1";
        default:
          return "域名需要后台更改";
      }
    }
  },
};
</script>
<style scoped>
.iconPosition {
  padding-left: 40px;
  display: flex;
  align-items: center;
}

.handleWords:hover {
  color: #0092de;
}

.footer-page {
  height: 274px;
  background: #313131;
  /* position: fixed;
  bottom: 0; */
}

.menuRow {
  height: 78px;
  align-items: center;
  justify-content: center;
}

.menuCol {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fbfbfb;
  letter-spacing: 3px;
}

.informationRow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #dfdfdf;
  letter-spacing: 4px;
  height: 36px;
}
.informationRow a {
  color: #dfdfdf;
  text-decoration: none;
}
.copyRight {
  height: auto;
}

.copyrightCol {
  margin-left: 77px;
}

.footer-page .el-divider--horizontal {
  width: 1250px;
  height: 1px;
  background: #9b9b9b;
  margin: 24px 321px;
}
</style>
