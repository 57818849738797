<template>
  <router-view></router-view>
</template>

<script>
export default {
  watch: {
    $route: function(to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  font-family: Microsoft YaHei, serif;
}

html {
  font-size: 10px;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 6px;
  /* 对应横向滚动条的宽度 */
  height: 6px;
}

/*滑块区域宽6px，滑块颜色#c9e5f5，背景#f2f7fa*/

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #3fa6dc;
  border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #c6deea;
  border-radius: 32px;
}
</style>
