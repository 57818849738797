<template>
  <!--  <div>一行二个元素</div>-->
  <div>
    <el-row class="sectionTwo">
      <el-col :span="4" :offset="6" class="ellipseCol">
        <el-row>
          <el-col :span="4">
            <img
              src="@/assets/photo/Operation/ellipse.png"
              class="ellipseImg"
            />
            <span class="ellipseNum">{{ section1.id }}</span>
          </el-col>
          <el-col :span="20">
            <el-row class="ellipseTitle">{{ section1.title }}</el-row>
            <el-row class="ellipseBody">{{ section1.body }}</el-row>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4" :offset="4" class="ellipseCol">
        <el-row>
          <el-col :span="4">
            <img
              src="@/assets/photo/Operation/ellipse.png"
              class="ellipseImg"
            />
            <span class="ellipseNum">{{ section2.id }}</span>
          </el-col>
          <el-col :span="20">
            <el-row class="ellipseTitle">{{ section2.title }}</el-row>
            <el-row class="ellipseBody">{{ section2.body }}</el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "TwoSection",
  props: {
    section1: Object,
    section2: Object,
  },
};
</script>

<style scoped lang="scss">
.sectionTwo {
  width: 86%;
  height: 120px;
  margin-left: 8%;
}
.ellipseCol {
  height: 12%;
  padding-top: 1%;
}

.ellipseTitle {
  height: 27px;
  border-bottom: 1px solid #999999;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  padding-left: 15px;
  margin-left: 4px;
}

.ellipseBody {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 3px;
  padding-left: 15px;
  margin-top: 4px;
  text-align: justify;
}

.ellipseImg {
  margin: 0 4px -3px;
}

.ellipseNum {
  font-size: 18px;
  font-family: Arial;
  font-weight: 400;
  color: #333333;
  margin: -35px 0 0 19px;
  position: absolute;
}
</style>
