<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">5G急救系统</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统特点</el-col>
      </el-row>
      <el-row class="characteristicsRow">
        <el-col :span="12">
          <el-row>
            <el-col :span="10">
              <div class="characteristicsTitle docking">
                呼叫即入院，院前急救与院内系统的无缝信息对接
              </div>
              <div class="subject dockingText">
                <el-row v-for="item in dockData" :key="item" class="alignment">
                  <el-col :span="1">
                    <span class="point"></span>
                  </el-col>
                  <el-col :span="23">{{ item.dockText }}</el-col>
                </el-row>
              </div>
              <img src="@/assets/photo/firstaid/rectangle1.png" alt="" />
            </el-col>
            <el-col :span="10" :offset="2">
              <div class="characteristicsTitle realization">
                利用5G网关技术和优享切片技术实现
              </div>
              <div class="subject technologyText">
                <el-row
                  v-for="technology in technologyData"
                  :key="technology"
                  class="alignment"
                >
                  <el-col :span="1">
                    <span class="point"></span>
                  </el-col>
                  <el-col :span="23">{{ technology.technologyText }}</el-col>
                </el-row>
              </div>
              <img src="@/assets/photo/firstaid/rectangle2.png" alt="" />
            </el-col>
          </el-row>
          <el-row class="equipmentRow">
            <div class="equipment">
              车内设备包含有呼吸机、心电图、心电监护、除颤监护仪、B超、吸痰机、POCT等
            </div>
          </el-row>
        </el-col>

        <el-col :span="8">
          <div class="characteristicsTitle aav">音视频功能</div>
          <div class="subject aavText">
            <el-row v-for="aav in aavData" :key="aav" class="alignment">
              <el-col :span="1">
                <span class="point"></span>
              </el-col>
              <el-col :span="23">{{ aav.aavText }}</el-col>
            </el-row>
          </div>
          <img src="@/assets/photo/firstaid/rectangle3.png" alt="" />
        </el-col>
      </el-row>
    </div>

    <div class="twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统展示</el-col>
      </el-row>

      <el-row class="imgRow">
        <el-col :span="7" :offset="5" class="img1">
          <!-- <img src="@/assets/photo/firstaid/img1.png" alt="" /> -->
          <el-image :src="img1Url" :preview-src-list="img1List" />
          <div class="operationText">显示器大屏</div>
        </el-col>
        <el-col :span="4">
          <!-- <img src="@/assets/photo/firstaid/img2.png" alt="" /> -->
          <el-image :src="img2Url" :preview-src-list="img2List" />
          <div class="operationText padWords">Pad</div>
        </el-col>
        <el-col :span="3" class="img3">
          <!-- <img src="@/assets/photo/firstaid/img3.png" alt="" /> -->
          <el-image :src="img3Url" :preview-src-list="img3List" />
          <div class="operationText">手机</div></el-col
        >
      </el-row>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { ref } from "@vue/reactivity";
export default {
  components: {
    Footer,
  },
  setup() {
    //正常的图片
    const img1Url = ref(require("@/assets/photo/firstaid/img1.png"));
    const img2Url = ref(require("@/assets/photo/firstaid/img2.png"));
    const img3Url = ref(require("@/assets/photo/firstaid/img3.png"));
    //点击后的图片
    const img1List = ref([
      require("@/assets/photo/el-img/Firstaid/el-img1.png"),
    ]);
    const img2List = ref([
      require("@/assets/photo/el-img/Firstaid/el-img2.png"),
    ]);
    const img3List = ref([
      require("@/assets/photo/el-img/Firstaid/el-img3.png"),
    ]);
    return {
      img1Url,
      img2Url,
      img3Url,
      img1List,
      img2List,
      img3List,
    };
  },
  data() {
    return {
      dockData: [
        { dockText: "生命体征数据自动记录" },
        { dockText: "急救病历的智能生成" },
        { dockText: "患者数据无缝转交接诊医生" },
        { dockText: "与院内急诊科信息集成对接" },
      ],
      technologyData: [
        { technologyText: "院前急救过程中与院内急诊中心音视频沟通和交流" },
        { technologyText: "车内临床数据实时传输到急救中心" },
        { technologyText: "车内场景实况高清传输到急救中心" },
      ],
      aavData: [
        { aavText: "支持手机+Pad视频监控、回传、伴音控制" },
        { aavText: "视频调度:终端到诊断的视频推送" },
        { aavText: "音频调度:终端到终端、调度台的全双工语音" },
        {
          aavText:
            "多媒体调度:终端和调度台间收发、群发文字消息、图片、音视频文件",
        },
        { aavText: "GIS:支持地图、运动轨迹及回放" },
      ],
    };
  },
};
</script>

<style scoped>
.oneRow {
  height: 608px;
}
.twoRow {
  height: 548px;
  background: #f5f5f7;
}
.imgRow {
  height: 52%;
  display: flex;
  align-items: flex-end;
}
.img1 {
  margin-left: 22%;
}
.img3 {
  margin-left: -2%;
}
.characteristicsRow {
  margin: 31px 0 59px 467px;
  height: 290px;
}
.equipmentRow {
  margin-top: 46px;
  width: 630px;
  height: 70px;
  background: #0092de;
  border-radius: 35px;
  padding: 0 5px;
}
.equipment {
  width: 546px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fbfbfb;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: 3px;
  margin: auto;
}
.characteristicsTitle {
  width: 208px;
  height: 42px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  letter-spacing: 2px;
  position: absolute;
}
.docking {
  margin-left: 36px;
  margin-top: -20px;
}
.realization {
  width: 159px;
  margin-top: -20px;
  margin-left: 60px;
}
.aav {
  width: 93px;
  margin-top: -10px;
  margin-left: 90px;
}
.point {
  width: 6px;
  height: 6px;
  background: #0092de;
  position: absolute;
  margin-top: 7px;
  margin-left: -6px;

  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.subject {
  width: 201px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  position: absolute;
  text-align: justify;
}
.dockingText {
  margin-top: 35px;
  margin-left: 37px;
}
.technologyText {
  margin-left: 40px;
  margin-top: 35px;
}
.aavText {
  margin-left: 50px;
  margin-top: 30px;
}

.alignment {
  margin-top: 3px;
}
.padWords {
  margin-left: -22px;
}
</style>
