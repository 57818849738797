import request from "@/utils/request";

// export function getnews(query) {
//   return request({
//     url: "/getnews",
//     method: "get",
//     params: query
//   });
// }
// export function getnewsdetail(query) {
//   return request({
//     url: "/getnewsdetail",
//     method: "get",
//     params: { id: query }
//   });
// }

export function getAllNews(form) { // 新闻应该分页
  return request({
    url: "/news/getnews",
    method: "get",
    params:
      {
        page: form.page,
        pageSize: form.pageSize
      }
  });
}

export function getlastest(query) {
  return request({
    url: "/news/lastest",
    method: "get",
    params: query
  });
}

export function getnewsdetail(query) {
  return request({
    url: "/news/newsdetail",
    method: "get",
    params: { id: query }
  });
}
