<template>
  <div class="productSwiper system">
    <div class="sketchTitle">产品中心</div>

    <div class="swiper-father product-father">
      <div class="swiper-container product">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            :class="'swiper' + index"
            v-for="(item, index) in slideList"
            :key="index"
          >
            <circular
              @click="chooseSystem(item.url)"
              :item="item.name"
            ></circular>
          </div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
        </div>
      </div>
      <div class="swiper-button-prev upper"></div>
      <div class="swiper-button-next lower"></div>
    </div>
  </div>
</template>
<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper";

import circular from "@/components/circular.vue";

export default {
  components: {
    circular,
  },
  data() {
    return {
      // content: "智能导诊系统",
      slideList: [
        {
          name: "AI随访系统",
          url: "/Followup",
        },
        {
          name: "5G急救系统",
          url: "/Firstaid",
        },
        {
          name: "人工智能超声、放射系统",
          url: "/Ultrasonic",
        },

        {
          name: "5G远程冰冻病理诊断",
          url: "/Pathology",
        },
        {
          name: "5G远程ROSE病理评估",
          url: "/Rose",
        },
        {
          name: "肿瘤辅助手术规划系统",
          url: "/Brain",
        },
        {
          name: "智能导诊系统",
          url: "/Guidance",
        },
        {
          name: "智能预问诊系统",
          url: "/Consultation",
        },
        {
          name: "5G多院区急诊移动协同系统",
          url: "/Coordination",
        },
        {
          name: "智能语音病历系统",
          url: "/Medicalrecord",
        },
        {
          name: "安全决策手术系统",
          url: "/Operation",
        },
        {
          name: "智能护理交互室系统",
          url: "/Nursing",
        },
        {
          name: "智能药箱药柜系统",
          url: "/Narcoticsystem",
        },
        {
          name: "智慧病理",
          url: "/wisdom",
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      new Swiper(".product", {
        // loop: true,
        observer: true,
        observeParents: true,
        slidesPerColumnFill: "row",
        slidesPerView: 3,
        slidesPerColumn: 2,
        slidesPerGroup: 3,
        navigation: {
          nextEl: ".lower",
          prevEl: ".upper",
        },
      });
    }, 300);
    // console.log(productSwiper);
  },
  methods: {
    chooseSystem(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
.product-father {
  position: relative;
}

.productSwiper {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  letter-spacing: 4px;
  height: 15%;
  line-height: 90px;
}

.swiper-father {
  width: 100%;
  height: 72%;
  padding-top: 20px;
}

.swiper-container {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.el-col {
  display: flex;
  justify-content: center;
}
</style>
