<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">5G远程冰冻病理诊断</div>
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统简介</el-col>
      </el-row>
      <el-row class="introduction">
        <el-col :span="8" :offset="2">
          <el-row class="introductionText">
            <el-col :span="1">1）</el-col>
            <el-col :span="22" :offset="1">
              病理医生在手机/电脑上调节在远程的切片放大系统，做病理诊断，无需扫描切片
            </el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">2）</el-col>
            <el-col :span="22" :offset="1"> 物镜4x/10x/20x/40x </el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">3）</el-col>
            <el-col :span="22" :offset="1"> 5G通讯，高清、毫秒级延时 </el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">4）</el-col>
            <el-col :span="22" :offset="1"> 5G、4G宽带任意切换 </el-col>
          </el-row>
          <el-row class="introductionText">
            <el-col :span="1">5）</el-col>
            <el-col :span="22" :offset="1">
              保留本地诊断操作，并可输出到显示器
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8" :offset="3" class="roseCol">
          <div class="greatCircle">
            <span class="greatCircleText">术中冰动切片</span>
          </div>
          <div class="box">
            <div class="boxText boxTop">在手术过程中，病理</div>
            <div class="boxText">医生要在30分钟之内</div>
            <div class="boxText">给手术医生出结果</div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="twoRow">
      <el-row class="functionalRow">
        <el-col :span="1" :offset="5" class="functionalImg">
          <img src="@/assets/photo/Medicalrecord/semicircle.png" />
        </el-col>
        <el-col class="functionalCol" :span="3">系统特点</el-col>
      </el-row>

      <rosepathology></rosepathology>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import rosepathology from "@/components/rosepathology.vue";
import "@/assets/css/system.css";
export default {
  name: "Pathology",
  components: {
    Footer,
    rosepathology,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.oneRow {
  height: 465px;
}
.twoRow {
  height: 743px;
  background: #f5f5f7;
}
.introduction {
  width: 70%;
  height: 63%;
  margin-left: 18%;
}
.introductionText {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
  margin-top: 10px;
  text-align: justify;
}
.roseCol {
  padding-top: 12px;
}
.greatCircle {
  width: 80px;
  height: 80px;
  background: #0092de;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 11;
}
.greatCircleText {
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #fbfbfb;
  letter-spacing: 4px;
  text-align: center;
  width: 68px;
  height: 42px;
}
.box {
  width: 240px;
  height: 120px;
  border: 1px solid #0092de;
  border-radius: 8px;
  margin: 37px 0 0 39px;
  position: relative;
}
.boxText {
  width: 265px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
  margin-left: 35px;
  text-align: justify;
  line-height: 22px;
}
.boxTop {
  margin-top: 30px;
}
</style>
