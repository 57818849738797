
<template>
  <!-- Swiper -->
  <!-- Slider main container -->
  <div class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide">Slide 1</div>
      <div class="swiper-slide">Slide 2</div>
      <div class="swiper-slide">Slide 3</div>
      ...
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>

    <!-- If we need scrollbar -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper/bundle";
export default {
  components: {
    // Swiper
    // Swiper,
    // Navigation,
    // SwiperSlide,
  },
  data() {
    return {};
  },
  mounted() {
    const swiper = new Swiper(".swiper", {
      // Optional parameters
      // direction: "vertical",
      loop: true,

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // And if we need scrollbar
      // scrollbar: {
      //   el: ".swiper-scrollbar",
      // },
    });

    console.log(swiper);
  },
  methods: {},
};
</script>

<style scoped>
.swiper-slide {
  height: 400px;
  background: red;
  width:600px;
}
</style>
