<template>
  <div class="RecruitPage system">
    <div class="recruit-container">
      <div class="sketchTitle">招聘岗位</div>

      <el-collapse>
        <el-collapse-item
          v-for="item in recruitList"
          :key="item"
          class="recruitRow"
          @click="handleChange"
        >
          <template #title>
            <div class="primaryTitle">
              {{ item.post }}
              <div>
                <span class="education">{{ item.education }}</span>
                <span class="man">{{ item.man }}</span>
              </div>
            </div>
          </template>
          <div>
            <div class="secondaryTitle">职位介绍：</div>
            <div class="content">
              {{ item.duty }}
            </div>
            <!-- <p>{{ item.duty }}</p> -->
            <div class="secondaryTitle">职位要求：</div>
            <div class="content">
              {{ item.requirement }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <Footer class="footer" :class="{ fixedFooter: isFixed }" />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { getrecruit } from "@/api/recruit.js";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      isFixed: false,
      timer: null,
      recruitList: [
        {
          post: "UI设计工程师",
          education: "本科及以上",
          man: "1人",
          duty:
            "1、负责参与具体的产品设计,根据产品需求完成UI的原型图和效果图设计； " +
            "2、注重用户体验和用户习惯,根据产品需实现的功能需求进行人机界面的UI设计； " +
            "3、搜集与准备各种图片、视频等素材,为UI设计中的界面、图标、动画等提供解决方案,完成产品的前端设计中需要的各类素材的加工与设计； " +
            "4、负责为指定的产品设计并制作产品海报或者其他相关的产品logo、包装等平面设计。 ",
          requirement:
            "1、本科或以上学历，美术与设计相关专业优先考虑； " +
            "2、有良好的审美观，对于网站和手机产品的UI及色彩的搭配有着独到的见解； " +
            "3、能够熟练使用Photoshop、Flash、Illustrator、sketch等软件； " +
            "4、能独立完成整个设计过程，具有一定的创新能力，了解流程图、线框图等交互设计方法； " +
            "5、出色的沟通及语言表达能力，良好的团队合作精神，较强的自学能力及吃苦耐劳的工作精神。 "
        },
        {
          post: "产品经理",
          education: "本科及以上",
          man: "1人",
        },
        {
          post: "UI设计工程师",
          education: "本科及以上",
          man: "1人",
          duty:
            "1、负责参与具体的产品设计,根据产品需求完成UI的原型图和效果图设计； " +
            "2、注重用户体验和用户习惯,根据产品需实现的功能需求进行人机界面的UI设计； " +
            "3、搜集与准备各种图片、视频等素材,为UI设计中的界面、图标、动画等提供解决方案,完成产品的前端设计中需要的各类素材的加工与设计； " +
            "4、负责为指定的产品设计并制作产品海报或者其他相关的产品logo、包装等平面设计。 ",
          requirement:
            "1、本科或以上学历，美术与设计相关专业优先考虑； " +
            "2、有良好的审美观，对于网站和手机产品的UI及色彩的搭配有着独到的见解； " +
            "3、能够熟练使用Photoshop、Flash、Illustrator、sketch等软件； " +
            "4、能独立完成整个设计过程，具有一定的创新能力，了解流程图、线框图等交互设计方法； " +
            "5、出色的沟通及语言表达能力，良好的团队合作精神，较强的自学能力及吃苦耐劳的工作精神。 "
        },
        {
          post: "产品经理",
          education: "本科及以上",
          man: "1人",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  mounted() {
    getrecruit().then(res => {
      console.log(res);
      this.recruitList = res.data;
    });
  },
  methods: {
    handleChange() {
    },
    // 判断元素高度超过了限制, 需要footer取消固定
    decideFooterPos() {
      const job = document.querySelector(".recruit-container");
      const jobToTop = job.getBoundingClientRect().top + job.offsetHeight; // 整个页面元素距离头部距离

      const footer = document.querySelector(".footer");
      const footerToTop = footer.getBoundingClientRect().top; // footer 距离头部距离
      // console.log(jobToTop, footerToTop);
      if (jobToTop >= footerToTop) {
        console.log("超过了");
        this.isFixed = false;
      } else {
        console.log("一切原样");
        this.isFixed = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.RecruitPage {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 154px);
}

.recruit-container {
  padding-bottom: 30px;
  flex: 1;
}

/*.fixedFooter {*/
/*  position: fixed;*/
/*  bottom: 0px;*/
/*  width: 100%;*/
/*  left: 0px;*/
/*}*/

/*.footer {*/
/*  width: 100%;*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*}*/

.recruitTitle {
  /* font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  height: 80px;
  line-height: 80px;
  letter-spacing: 7px; */
}

.el-divider--horizontal {
  width: 1140px;
  height: 1px;
  background: #999999;
  margin: 0 0 24px 0;
}

.recruitRow {
  margin: 0 20%;
}

.oneRow {
  height: 70px;
}

.primaryTitle {
  width: 95%;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 4px;
}

.secondaryTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  letter-spacing: 2px;
}

.education {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  letter-spacing: 2px;
}

.man {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0092de;
  margin-left: 80px;
}

.content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin: 15px 0;
  letter-spacing: 3px;
  white-space: pre-line;
}
</style>

<style>
.el-collapse {
  border: none;
  --el-collapse-border-color: #999999;
}

.el-collapse-item__header {
  height: 85px;
  line-height: 30px;
}

.RecruitPage .el-icon-arrow-right:before {
  content: "";
}

.el-collapse-item__arrow {
  width: 40px;
  height: 40px;
  background: url(../assets/photo/recruit/add.png);
}

.el-collapse-item__arrow.is-active {
  transform: none;
  background: url(../assets/photo/recruit/reduce.png);
}
</style>
