<template>
  <div class="breadcrumb-container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="item in breadcrumbs"
        :key="item.title"
        :to="{ path: item.index }"
      >{{ item.title }}
      </el-breadcrumb-item
      >
      <el-breadcrumb-item v-if="isNewsDetailPage">{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data () {
    return {
      breadcrumbs: null,
      breadcrumbInfo: null,
      title: "",
      isNewsDetailPage: "",
    };
  },
  computed: {
    key () {
      return this.$route.name !== undefined
        ? this.$route.name + new Date()
        : this.$route + new Date();
    },
  },
  watch: {
    $route (to, from) {
      this.getBreadcrumb();
      setTimeout(() => {
        this.getBreadcrumb();
      }, 200);
    }
  },
  mounted () {
    this.getBreadcrumb();
    setTimeout(() => {
      this.getBreadcrumb();
    }, 200);
    // console.log("重新创建");
  },

  methods: {
    getBreadcrumb () {
      console.log(this.$route.meta);
      this.title = document.title;
      console.log(this.title);
      const breadcrumb = this.$route.meta.breadcrumbInfo;
      const b = [];
      const path = this.$route.path;
      console.log(path);
      if (path === "/Newsdetail") {
        b.push(breadcrumb.itsFather);
        this.isNewsDetailPage = true;
      } else if (breadcrumb.itsFather) {
        this.isNewsDetailPage = false;
        // 三层面包屑
        b.push(breadcrumb.itsFather);
        b.push({
          index: breadcrumb.index,
          title: this.$route.meta.title,
        });
      } else {
        this.isNewsDetailPage = false;
        b.push({
          index: breadcrumb.index,
          title: this.$route.meta.title,
        });
      }
      this.breadcrumbs = b;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-container {
  padding: 0 398px;

  //   background: #fbfbfb;
  > div {
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #999999;
  }

  font-size: 16px;

  font-weight: 400;
  color: #666666;
  line-height: 121px;
}

// .el-icon-arrow-right:before {
//   content: "\e6e0" !important;
// }
.el-breadcrumb__item {
  span {
    cursor: pointer;
  }
}
</style>

<style>
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #0092de;
}
</style>
