import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Test1 from "../views/Test1.vue";
import Index from "../views/Index";
import Aboutus from "../views/Aboutus.vue";
import Recruit from "../views/Recruit.vue";
import Newsinformation from "../views/Newsinformation.vue";
import Products from "../views/Products.vue";
import Guidance from "../views/Guidance.vue";
import Newsdetail from "../views/Newsdetail.vue";
import Consultation from "../views/Consultation.vue";
import Ultrasonic from "../views/Ultrasonic.vue";
import Medicalrecord from "../views/Medicalrecord.vue";
import Operation from "../views/Operation.vue";
import Nursing from "../views/Nursing.vue";
import Followup from "../views/Followup.vue";
import Firstaid from "../views/Firstaid.vue";
import Coordination from "../views/Coordination.vue";
import Pathology from "../views/Pathology.vue";
import Rose from "../views/Rose.vue";
import Brain from "../views/Brain.vue";
import Wisdom from "../views/Wisdom.vue";
import Narcoticsystem from "../views/Narcoticsystem";

import Test2 from "../views/Test2.vue";

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/test1",
    name: "Test1",
    component: Test1
  },
  {
    path: "/test2",
    name: "Test2",
    component: Test2
  },
  {
    //
    path: "/index",
    component: Home,
    children: [
      {
        path: "/index",
        component: Index,
        name: "Index"
      }
    ],
    meta: {
      title: "首页",
      roles: ["admin", "user"],
      breadcrumbInfo: {
        index: "/index"
      },
    }
  },
  {
    //
    path: "/products",
    component: Home,
    children: [
      {
        path: "/products",
        component: Products,
        name: "Products"
      }
    ],
    meta: {
      title: "产品中心",
      breadcrumbInfo: {
        index: "/products"
      },
      roles: ["admin", "user"]
    }
  },
  {
    //
    path: "/aboutus",
    component: Home,
    children: [
      {
        path: "/aboutus",
        component: Aboutus,
        name: "Aboutus"
      }
    ],
    meta: {
      title: "关于我们",
      breadcrumbInfo: {
        index: "/aboutus"
      },
      roles: ["admin", "user"]
    }
  },
  {
    //
    path: "/recruit",
    component: Home,
    children: [
      {
        path: "/recruit",
        component: Recruit,
        name: "Recruit"
      }
    ],
    meta: {
      title: "招贤纳士",
      breadcrumbInfo: {
        index: "/recruit"
      }
    }
  },
  {
    //
    path: "/newsinformation",
    component: Home,
    children: [
      {
        path: "/newsinformation",
        component: Newsinformation,
        name: "Newsinformation"
      }
    ],
    meta: {
      title: "新闻动态",
      breadcrumbInfo: {
        index: "/newsinformation"
      }
    }
  },
  {
    //
    path: "/guidance",
    component: Home,
    children: [
      {
        path: "/guidance",
        component: Guidance,
        name: "Guidance"
      }
    ],
    meta: {
      title: "智能导诊系统",
      breadcrumbInfo: {
        index: "/Guidance",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/newsdetail",
    component: Home,
    children: [
      {
        path: "/newsdetail",
        component: Newsdetail,
        name: "Newsdetail"
      }
    ],
    meta: {
      title: "",
      breadcrumbInfo: {
        index: "",
        itsFather: {
          index: "/newsinformation",
          title: "新闻动态"
        }
      }
    }
  },
  {
    //
    path: "/consultation",
    component: Home,
    children: [
      {
        path: "/consultation",
        component: Consultation,
        name: "Consultation"
      }
    ],
    meta: {
      title: "智能预问诊系统",
      breadcrumbInfo: {
        index: "/consultation",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/ultrasonic",
    component: Home,
    children: [
      {
        path: "/ultrasonic",
        component: Ultrasonic,
        name: "Ultrasonic"
      }
    ],
    meta: {
      title: "人工智能语音超声、放射系统",
      breadcrumbInfo: {
        index: "/ultrasonic",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/medicalrecord",
    component: Home,
    children: [
      {
        path: "/medicalrecord",
        component: Medicalrecord,
        name: "Medicalrecord"
      }
    ],
    meta: {
      title: "智能语音病历系统",
      breadcrumbInfo: {
        index: "/medicalrecord",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/operation",
    component: Home,
    children: [
      {
        path: "/operation",
        component: Operation,
        name: "Operation"
      }
    ],
    meta: {
      title: "安全决策手术系统",
      breadcrumbInfo: {
        index: "/operation",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/nursing",
    component: Home,
    children: [
      {
        path: "/nursing",
        component: Nursing,
        name: "Nursing"
      }
    ],
    meta: {
      title: "智能护理交互室系统",
      breadcrumbInfo: {
        index: "/nursing",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/followup",
    component: Home,
    children: [
      {
        path: "/followup",
        component: Followup,
        name: "Followup"
      }
    ],
    meta: {
      title: "AI随访系统",
      breadcrumbInfo: {
        index: "/followup",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/firstaid",
    component: Home,
    children: [
      {
        path: "/firstaid",
        component: Firstaid,
        name: "Firstaid"
      }
    ],
    meta: {
      title: "5G急救系统",
      breadcrumbInfo: {
        index: "/firstaid",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/coordination",
    component: Home,
    children: [
      {
        path: "/coordination",
        component: Coordination,
        name: "Coordination"
      }
    ],
    meta: {
      title: "5G多院区急诊移动协同系统",
      breadcrumbInfo: {
        index: "/coordination",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/pathology",
    component: Home,
    children: [
      {
        path: "/pathology",
        component: Pathology,
        name: "Pathology"
      }
    ],
    meta: {
      title: "5G远程冰冻病理诊断",
      breadcrumbInfo: {
        index: "/pathology",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/rose",
    component: Home,
    children: [
      {
        path: "/rose",
        component: Rose,
        name: "Rose"
      }
    ],
    meta: {
      title: "5G远程ROSE病理评估",
      breadcrumbInfo: {
        index: "/rose",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/brain",
    component: Home,
    children: [
      {
        path: "/brain",
        component: Brain,
        name: "Brain"
      }
    ],
    meta: {
      title: "肿瘤辅助手术规划系统",
      breadcrumbInfo: {
        index: "/brain",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/narcoticsystem",
    component: Home,
    children: [
      {
        path: "/narcoticsystem",
        component: Narcoticsystem,
        name: "Narcoticsystem"
      }
    ],
    meta: {
      title: "智能药箱药柜系统",
      breadcrumbInfo: {
        index: "/narcoticsystem",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  },
  {
    //
    path: "/wsdom",
    component: Home,
    children: [
      {
        path: "/wisdom",
        component: Wisdom,
        name: "Wisdom"
      }
    ],
    meta: {
      title: "智慧病理",
      breadcrumbInfo: {
        index: "/wisdom",
        itsFather: {
          index: "/products",
          title: "产品中心"
        }
      }
    }
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
const defaultTitle = "信瑞医疗官方网站";
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  // document.title = to.meta.title
  next();
});

export default router;
