import request from "@/utils/request";
//
// export function fetchList(query) {
//   return request({
//     url: '/getswiper',
//     method: 'get',
//     params: query
//   })
// }

export function getswiper() {
  return request({
    url: "/swiper/getswipers",
    method: "get",
    params: {}
  });
}
