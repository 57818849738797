<template>
  <div class="system">
    <div class="sketch oneRow">
      <div class="sketchTitle">智能预问诊系统</div>
      <div class="sketchText">
        <div class="words">
          患者可以通过和具有专业医生问诊能力的预问诊系统进行交流，系统模仿医生的问诊逻辑，提供采集患者的主诉，既往史，过敏史，家族史等病历等信息，自动生成结构化的电子病历报告，提高诊疗效率和电子病历质量，响应国家电子病历评级。为医生面诊提供尽可能完备的病人病史信息。
        </div>
      </div>
      <div class="sketchImg">
        <div class="animation-image hideElement">
          <el-image :src="consultationUrl" :preview-src-list="consultationList" append-to-body />
          <div class="explain">问诊页面</div>
        </div>
        <div class="animation-image hideElement">
          <img
            class="arrow"
            src="@/assets/photo/Consultation/arrow.png"
            alt=""
          />
          <div class="explain">智能分析</div>
        </div>
        <div></div>
        <div class="animation-image hideElement">
          <el-image :src="report1Url" :preview-src-list="report1List" append-to-body />
          <el-image :src="report2Url" :preview-src-list="report2List" append-to-body />
          <div class="explain">报告生成页面</div>
        </div>
      </div>
    </div>
    <div class="functionalArea twoRow">
      <el-row class="imgRow">
        <el-col :span="4">
          <span class="functionalWords"
            >有医疗知识图谱AI，从大量的结构化或非结构化的医学数据中提取出实体、关系、属性等知识图谱的组成元素。</span
          >
        </el-col>
        <el-col :span="10" :offset="1">
          <span class="functionalImg">
            <!-- <img src="@/assets/photo/Consultation/resources1.png" alt="" /> -->
            <el-image :src="resourcesUrl" :preview-src-list="resourcesList" />
            <!-- <img
              src="@/assets/photo/Consultation/resources2.png"
              alt=""
              class="resources2"
            /> -->
            <el-image
              :src="resources1Url"
              :preview-src-list="resources1List"
              class="resources2"
            />
          </span>
        </el-col>
      </el-row>
    </div>

    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import "@/assets/css/system.css";
import { ref } from "@vue/reactivity";

export default {
  components: {
    Footer,
  },
  setup() {
    //正常的图片
    const resourcesUrl = ref(
      require("@/assets/photo/Consultation/resources1.png")
    );
    const resources1Url = ref(
      require("@/assets/photo/Consultation/resources2.png")
    );
    const consultationUrl = ref(
      require("@/assets/photo/Consultation/consultation.png")
    );
    const report1Url = ref(
      require("@/assets/photo/Consultation/report1.png")
    );
    const report2Url = ref(
      require("@/assets/photo/Consultation/report2.png")
    );
    //点击后的图片
    const resourcesList = ref([
      require("@/assets/photo/el-img/Consultation/el-resources1.png"),
    ]);
    const resources1List = ref([
      require("@/assets/photo/el-img/Consultation/el-resources2.png"),
    ]);
    const consultationList = ref([
      require("@/assets/photo/el-img/Consultation/el-consultation.png"),
    ]);
    const report1List = ref([
      require("@/assets/photo/el-img/Consultation/el-report1.png"),
    ]);
    const report2List = ref([
      require("@/assets/photo/el-img/Consultation/el-report2.png"),
    ]);
    return {
      resourcesUrl,
      resources1Url,
      consultationUrl,
      report1Url,
      report2Url,
      resourcesList,
      resources1List,
      consultationList,
      report1List,
      report2List
    };
  },
  mounted() {
    this.playAnimation();
  },
  methods: {
    playAnimation() {
      const eles = document.getElementsByClassName("animation-image");
      eles[0].classList.add("fade-in");
      setTimeout(() => {
        eles[1].classList.add("fade-in");
        setTimeout(() => {
          eles[2].classList.add("fade-in");
          setTimeout(() => {
            eles[3].classList.add("fade-in");
          }, 700);
        }, 500);
      }, 500);
    },
  },
};
</script>
<style scoped>
.hideElement {
  opacity: 0%;
}

.fade-in {
  animation: fade-in; /*动画名称*/
  animation-duration: 1s; /*动画持续时间*/
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  /*结束状态 透明度为1*/
}

.oneRow {
  height: 799px;
}

.twoRow {
  height: 560px;
}

.arrow {
  width: 94px;
  height: 44px;
}
.imgRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 100px 0;
}
.functionalWords {
  width: 255px;
  height: 115px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  line-height: 30px;
  text-align: justify;
}

.functionalImg {
  margin-left: 7%;
  width: 580px;
}

.resources2 {
  margin-left: 30px;
}
</style>
