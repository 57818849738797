<template>
  <div class="honorSwiper">
    <div class="swiper-father honor-father">
      <div class="swiper-container honor">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <el-image
              :src="honor9Url"
              :preview-src-list="honor9List"
              append-to-body="true"
            />
          </div>
          <div class="swiper-slide">
            <el-image
              :src="honor10Url"
              :preview-src-list="honor10List"
              append-to-body="true"
            />
          </div>

          <div class="swiper-slide">
            <el-image
              :src="honorUrl"
              :preview-src-list="honorList"
              append-to-body="true"
            />
          </div>
          <div class="swiper-slide">
            <el-image
              :src="honor2Url"
              :preview-src-list="honor2List"
              append-to-body="true"
            />
          </div>
          <div class="swiper-slide">
            <el-image
              :src="honor3Url"
              :preview-src-list="honor3List"
              append-to-body="true"
            />
          </div>
          <div class="swiper-slide">
            <el-image
              :src="honor4Url"
              :preview-src-list="honor4List"
              append-to-body="true"
            />
          </div>

          <div class="swiper-slide">
            <el-image
              :src="honor5Url"
              :preview-src-list="honor5List"
              append-to-body="true"
            />
          </div>
          <div class="swiper-slide">
            <el-image
              :src="honor6Url"
              :preview-src-list="honor6List"
              append-to-body="true"
            />
          </div>
          <div class="swiper-slide">
            <el-image
              :src="honor7Url"
              :preview-src-list="honor7List"
              append-to-body="true"
            />
          </div>
          <div class="swiper-slide">
            <el-image
              :src="honor8Url"
              :preview-src-list="honor8List"
              append-to-body="true"
            />
          </div>
        </div>
      </div>
      <div class="swiper-button-prev prevBtn"></div>
      <div class="swiper-button-next nextBtn"></div>
    </div>
  </div>
</template>
<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper";
import { ref } from "@vue/reactivity";
export default {
  components: {},
  setup() {
    //正常的图片
    const honorUrl = ref(require("@/assets/photo/Aboutus/honor1.png"));
    const honor2Url = ref(require("@/assets/photo/Aboutus/honor2.png"));
    const honor3Url = ref(require("@/assets/photo/Aboutus/honor3.png"));
    const honor4Url = ref(require("@/assets/photo/Aboutus/honor4.png"));
    const honor5Url = ref(require("@/assets/photo/Aboutus/honor5.png"));
    const honor6Url = ref(require("@/assets/photo/Aboutus/honor6.png"));
    const honor7Url = ref(require("@/assets/photo/Aboutus/honor7.png"));
    const honor8Url = ref(require("@/assets/photo/Aboutus/honor8.png"));
    const honor9Url = ref(require("@/assets/photo/Aboutus/honor9.jpg"));
    const honor10Url = ref(require("@/assets/photo/Aboutus/honor10.jpg"));
    //点击后的图片
    const honorList = ref([
      require("@/assets/photo/el-img/honor/el-honor1.png"),
    ]);
    const honor2List = ref([
      require("@/assets/photo/el-img/honor/el-honor2.png"),
    ]);
    const honor3List = ref([
      require("@/assets/photo/el-img/honor/el-honor3.png"),
    ]);
    const honor4List = ref([
      require("@/assets/photo/el-img/honor/el-honor4.png"),
    ]);
    const honor5List = ref([
      require("@/assets/photo/el-img/honor/el-honor5.png"),
    ]);
    const honor6List = ref([
      require("@/assets/photo/el-img/honor/el-honor6.png"),
    ]);
    const honor7List = ref([
      require("@/assets/photo/el-img/honor/el-honor7.png"),
    ]);
    const honor8List = ref([
      require("@/assets/photo/el-img/honor/el-honor8.png"),
    ]);

    const honor9List = ref([require("@/assets/photo/Aboutus/honor9.jpg")]);
    const honor10List = ref([require("@/assets/photo/Aboutus/honor10.jpg")]);
    return {
      honorUrl,
      honor2Url,
      honor3Url,
      honor4Url,
      honor5Url,
      honor6Url,
      honor7Url,
      honor8Url,
      honor9Url,
      honor10Url,
      honorList,
      honor2List,
      honor3List,
      honor4List,
      honor5List,
      honor6List,
      honor7List,
      honor8List,
      honor9List,
      honor10List,
    };
  },
  data() {
    return {};
  },
  mounted() {
    new Swiper(".honor", {
      autoplay: false,
      // observer: true,
      observeParents: true,
      slidesPerView: 4,
      navigation: {
        nextEl: ".nextBtn",
        prevEl: ".prevBtn",
      },
    });
  },
};
</script>
<style scoped>
.honorSwiper {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.honor-father {
  width: 1196px;
  position: relative;
}
.swiper-container {
  width: 90%;
}
.swiper-slide {
  width: 25%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>
